 import React, { useState } from 'react'
import { Eucc } from '../models/eucc'
import { Heading } from 'suomifi-ui-components';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { t } from 'i18next';

type Props = { issuerDid: string,  vc: Eucc }

const EuccView = (props: Props) => {
  const vc = props.vc;
  const issuerDid = props.issuerDid;

  const [visualize, setVisualize] = useState<boolean>(true);
  return (
    <div>

      <FormGroup>
        <FormControlLabel 
          control={<Switch checked={visualize} 
          onChange={() => setVisualize(old => !old)} />} 
          label={t("credentialView.visualize")} 
          labelPlacement="start" 
        />
      </FormGroup>

      { !visualize ? <pre>{JSON.stringify(vc, null, 2)}</pre> :
      <div>
        <p>
          Issuer: { vc.legalIdentifier.issuingAuthorityName }<br/>
          {/* Issuer Business ID: { vc.legalIdentifier.notation }<br/> */}
          Date and time of creating the document: { vc.legalIdentifier.dateOfIssue }<br/>
        </p>
        <Heading variant='h3'>TRANSLATION OF THE EXTRACT FROM THE TRADE REGISTER, ELECTRONIC</Heading>
        <br/>
        
        
        <table cellSpacing={0} style={{textAlign: "left"}}>
          <tbody>
          <tr>
            <th colSpan={3}>
              <Heading variant='h4' style={{borderBottom: "1px solid black", display: "inline"}}>Identifiers</Heading>
            </th>
          </tr>
          <tr>
            <td title="Name of the company">Company name</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.name}</td>
          </tr>
          {/* <tr>
            <td title="Finnish Business Identity Code">Business ID</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.notation}</td>
          </tr> */}
          <tr>
            <td title="EU Business Identity Code">EUID</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.euid.notation}</td>
          </tr>
          <tr>
            <td title="Date on which the PRH has made the register entry">Registration date</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.registrationDate}</td>
          </tr>
          <tr>
            <td>Company type</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.legalForm.name||vc.legalForm.code}</td>
          </tr>
          <tr>
            <td title="Postal address">Contact details:</td>
            <td style={{minWidth: "20px"}}></td>
            <td></td>
          </tr>
          {vc.postalAddress.poBox && 
            <tr>
              <td title="Postal address">Postall address</td>
              <td style={{minWidth: "20px"}}></td>
              <td>PL {vc.postalAddress.poBox} {vc.postalAddress.postName}</td>
            </tr>
          }
          <tr>
            <td title="Street address">Street address</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.postalAddress.fullAddress}</td>
          </tr>

          <tr><td><br/></td></tr>
          <tr>
            <th>
              <Heading variant='h4' style={{borderBottom: "1px solid black", display: "inline"}} title="Domicile (municipality where the company is managed, place of registered office)">Registered office</Heading>
            </th>
            <td></td>
            <td>{vc.registeredOffice.postName} {vc.registeredOffice.adminUnitL1}</td>
          </tr>
        
          <tr><td><br/></td></tr>
          <tr>
            <th colSpan={3}>
              <Heading variant='h4' style={{borderBottom: "1px solid black", display: "inline"}}>Share capital</Heading>
            </th>
          </tr>

          <tr>
            <td title="Amount of share capital">Share capital</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.sharecapital.amount} {vc.sharecapital.currency.replace("EUR", "€")}</td>
          </tr>
          </tbody>
        </table>

        {/* <Heading variant='h4' style={{borderBottom: "1px solid black", display: "inline"}}>Financial period</Heading>
        <table>
          <tr>
            <td>Financial period</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.durationOfTheLegalEntity}</td>  01.01 - 31.12
          </tr>
        </table> */}

        <br/>
        <table cellSpacing={0} width={"100%"} style={{textAlign: "left"}}>
          <tbody>
          <tr>
            <th colSpan={4}>
              <Heading variant='h4' style={{borderBottom: "1px solid black", display: "inline"}}>Persons</Heading>
            </th>
          </tr>
          <tr>
            <th>role</th>
            <th>surname, first name(s) / company name</th>
            <th>date of birth / identity code</th>
            <th>citizenship</th>
          </tr>
          {/* Model is not so suitable here... it should have memberships mapped to company, not companies mapped to single membership.. */}
          { vc.hasSignatoryRights && vc.hasSignatoryRights.map(rights =>
            <>
              { rights.hasSignatoryRule && rights.hasSignatoryRule.map(rules => 
                <> 
                { rules.hasMembershipRequirements && rules.hasMembershipRequirements.map(requirements =>
                  <>
                    {requirements.membership && requirements.membership.map(membership => 
                      <tr>
                        <td>{ membership.role[0].name || membership.role[0].code }</td>
                        <td>{ membership.memberPerson[0].fullName }</td>
                        <td>{ membership.memberPerson[0].dateOfBirth }</td>
                        <td>
                          Citizen of Finland
                        </td>
                      </tr>
                      )
                    }
                  </>
                  )
                }
                </>
              )
            }
            </> 
          )}
          </tbody>
        </table>
        <br/>
        <table cellSpacing={0} style={{textAlign:"left"}}>
          <tbody>
          <tr>
            <th>
              <Heading variant='h4' style={{borderBottom: "1px solid black", display: "inline"}}>Name history</Heading>
            </th>
          </tr>
          <tr>
            <td>{vc.name} {vc.registrationDate} - present</td>
          </tr>
          <tr>
            <td><p>Source of information: { vc.legalIdentifier.issuingAuthorityName }</p></td>
          </tr>
          </tbody>
        </table>

        <br/>
        <table cellSpacing={0}>
          <tbody>
            <tr>
              <td style={{verticalAlign: "center"}}>Date of issuance</td><td style={{width: "40px"}}></td>
              <td style={{verticalAlign: "center"}}>E-signature</td>
            </tr>
            <tr>
              <td>
                <div style={{borderBottom: "1px solid black"}}>{ vc.legalIdentifier.dateOfIssue||new Date().toLocaleDateString(/*selectedLanguage*/)}</div>
              </td>
              <td></td>
              <td><div style={{borderBottom: "1px solid black"}}><a href="https://prh.minisuomi.net" rel="noreferrer" target="_blank" title={vc.legalIdentifier?.issuingAuthorityName}>{issuerDid}</a></div></td>
            </tr>
          </tbody>
        </table>
      </div> 
      }
    </div>
  )
}

export default EuccView