import { Box } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { Block, Button, Modal, ModalContent, ModalFooter, ModalTitle } from 'suomifi-ui-components';

function Footer() {
  const { t, i18n } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const lang = i18n.language.substring(0,2);

  const capitalizeFirstLetter = (str: string) => {
    return str.replace(/./, (firstLetter) => { return firstLetter.toUpperCase() })
  }
 
  const showDataProtection = async () => {
    const url = `https://minisuomi.fi/data/data-protection-${lang}.md`;
    const response = await fetch(url);
    const content = await response.text();
    setTitle(capitalizeFirstLetter(t("app.footer.dataProtection")));
    setContent(content);
    setVisible(true);
  }

  const showAccessibilityStatement = async () => {
    const response = await fetch(`https://minisuomi.fi/data/accessibility-${lang}.md`);
    const content = await response.text();
    setTitle(capitalizeFirstLetter(t("app.footer.accessibilityStatement")));
    setContent(content);
    setVisible(true);
  }

  const showContact = async () => {
    const response = await fetch(`https://minisuomi.fi/data/contact-${lang}.md`);
    const content = await response.text();
    setTitle(capitalizeFirstLetter(t("app.footer.contactInformation")));
    setContent(content);
    setVisible(true);
  }

  return (
    <Block variant='footer' style={{ borderTop: "1px solid #ccc"}}>
      
      <Box sx={{ maxWidth: "1200px", paddingRight: "10px", paddingLeft: "10px", marginLeft: "auto", marginRight: "auto", textAlign: "center", padding: "30px" }}>       
        <Link to="/data-protection" onClick={ (e) => { e.preventDefault(); showDataProtection() }}>{t("footer.dataProtection")}</Link>
        &nbsp;|&nbsp;
        <Link to="/accessibility-statement" onClick={ (e) => { e.preventDefault(); showAccessibilityStatement() }}>{t("footer.accessibilityStatement")}</Link>
        &nbsp;|&nbsp;
        <Link to="/contact" onClick={ (e) => { e.preventDefault(); showContact() }}>{t("footer.contactInformation")}</Link>
      </Box>

      <Modal
        appElementId="root"
        visible={visible}
        onEscKeyDown={() => setVisible(false)}
      >
        <ModalContent>
          <ModalTitle>{ title }</ModalTitle>
          <Markdown>
           { content }
          </Markdown>
        </ModalContent>
        <ModalFooter>
          <Button arial-label={t("footer.ariaClose")} onClick={() => setVisible(false)}>
            { t("close") }
          </Button>
        </ModalFooter>
      </Modal>
    </Block>
  )
}

export default Footer