import { Box, Grid } from '@mui/material'
import { useState } from 'react'
import { Form,  Navigate,  useActionData, useNavigate, useNavigation } from 'react-router-dom'
import { Button, IconLogin, TextInput, Notification, LoadingSpinner } from 'suomifi-ui-components'
import { useTranslation } from 'react-i18next'
//import { useUserContext } from '../../context/UserContext'
import vceApi from '../../vceApi'
import RequestCredential from '../../components/RequestCredential'
import { useUserContext } from '../../context/UserContext'
import { SimplifiedPresentationPayload } from '../../api/vce-api/vce-api'


export interface IActionData {
  error?: string,
  datetime: Date
}


const LoginView = () => {
  const actionData = useActionData() as IActionData | undefined;
  const navigation = useNavigation();
  const { t } = useTranslation();
  const userContext = useUserContext();
  const navigate = useNavigate();

  //const loading = navigation.state === "loading";
  const submitting = navigation.state === "submitting";

  const [hideErrorDateTime, setHideErrorDateTime] = useState<Date>(new Date()); 

  // If already logged in then redirect to home page
  if (userContext.isLoggedIn) {
    return <Navigate to={`/`}></Navigate>
  }

  const onRequestCallback = (data: SimplifiedPresentationPayload) => {
    
    const family_name = data.credentials[0].vc?.credentialSubject?.family_name;
    const given_names = data.credentials[0].vc?.credentialSubject?.given_name || data.credentials[0].vc?.credentialSubject?.given_names;
    const username = `${given_names} ${family_name}`;
    localStorage.setItem("user", username);
    //localStorage.setItem("userId", )
    //localStorage.setItem("company", companyName);
    //localStorage.setItem("companyId", companyId);
    //localStorage.setItem("authorizationRoles", JSON.stringify(data.authorizationRoles)); // TODO?
    //localStorage.setItem("refreshTime", (new Date().getTime()/1000).toString());
    userContext.setName(username);

    //userContext.setCompany(companyName);
    //userContext.setCompanyId(companyId);
    userContext.setIsLoggedIn(true); 
    navigate("/");
  }

  const onRequestCallback1 = (data: SimplifiedPresentationPayload) => {
    const family_name = data.credentials[0].last_name;
    const given_names = data.credentials[0].given_name;
    const username = `${given_names} ${family_name}`;
    localStorage.setItem("user", username);
    userContext.setName(username);
    userContext.setIsLoggedIn(true); 
    navigate("/");
  }
  
  const onRequestCallback2 = (data: SimplifiedPresentationPayload) => {
    const family_name = data.credentials[0].family_name;
    const given_names = data.credentials[0].given_name;
    const username = `${given_names} ${family_name}`;
    localStorage.setItem("user", username);
    userContext.setName(username);
    userContext.setIsLoggedIn(true); 
    navigate("/");
  }

  const doSuomiFiAuthentication = () => {
    const path = window.location.origin.replace(".net",".fi") + "/suomifi?authentication=true";
    vceApi.sessions.getAuthorizeUrl({ relayState: path }).then(x => {      
      x.json().then(x => {
        if (x.redirect_uri) {
          window.location.href = x.redirect_uri;
        }
        console.log("ERROR: failed to get redirect uri!");
      });
    })
  }

  return (
    <>
      <Box sx={{
        marginTop: 3,
        padding: 1,
        margin: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>

        <a href="https://dev-i4mlab.aegean.gr/pid">GET PID for EWC Holder Wallet</a>
        <p>mouse over qr code will show required credential name, type and claims and wallets</p>
        
        <div style={{ display: 'flex', flexDirection: "row", justifyContent: "flex-start", gap: "20px", marginTop: "10px", marginBottom: "10px", flexWrap: "wrap"}}>
          {/* <DvvWalletLogin></DvvWalletLogin> */}
          {/* <Dvv callback={ onDvvCallback } ></Dvv>   */}
          
          <RequestCredential callback={ onRequestCallback } credentialType='PID' title="PID (did)" claimsCSV='family_name,given_name' client_id_scheme='did'></RequestCredential>
          {/* <RequestCredential callback={ onRequestCallback1 } credentialType='VerifiablePortableDocumentA1' title='VerifiablePortableDocumentA1' claimsCSV='last_name,given_name' client_id_scheme="redirect_uri"></RequestCredential> */}
          {/* <RequestCredential callback={ onRequestCallback2 } credentialType='PID001' claimsCSV='family_name,given_name,birth_date,resident_address,gender,issuer_name,issuer_id,issuer_country,issuance_date,expire_date,document_number,administrative_number'></RequestCredential> */}
          
          <RequestCredential callback={ onRequestCallback2 } credentialType='PID' title="PID (redirect_uri)" claimsCSV='family_name,given_name,birth_date,resident_address,gender,issuer_name,issuer_id,issuer_country' client_id_scheme="redirect_uri" pathType='sd-jwt'></RequestCredential>
          
          {/* <RequestCredential callback={ onRequestCallback2 } credentialType='PID001' title="PID (PID001)" claimsCSV='family_name,given_name,birth_date,resident_address,gender,issuer_name,issuer_id,issuer_country' client_id_scheme="redirect_uri" pathType='sd-jwt'></RequestCredential>
          <RequestCredential callback={ onRequestCallback2 } credentialType='PIDEWC' title="PID (PIDEWC)" claimsCSV='family_name,given_name,birth_date,resident_address,gender,issuer_name,issuer_id,issuer_country' client_id_scheme="redirect_uri" pathType='sd-jwt'></RequestCredential>
           */}
          {/* <RequestCredential callback={ onRequestCallback2 } credentialType='PID001SdJwt' claimsCSV='family_name,given_name,birth_date,resident_address,gender,issuer_name,issuer_id,issuer_country,issuance_date,expire_date,document_number,administrative_number'></RequestCredential> */}
        </div>

        <hr style={{ margin: "20px", width: "100%" }} />

        <Button onClick={doSuomiFiAuthentication}>Suomi.fi</Button>

        <hr style={{ margin: "20px", width: "100%" }} />

        {/* Basic login */}
        {/* <p style={{ textAlign: "center" }}>Basic</p> */}
        <Box>
          {/* <Box component="form" method="post" action={location.pathname} onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} width={320}> */}
          <Form method='post'>
            <TextInput className='input--custom'
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              autoFocus labelText={t("loginView.email")} />
            <TextInput className='input--custom'
              required
              fullWidth
              name="password"
              labelText={t("loginView.password")}
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <br />
            <Button iconRight={<IconLogin />}
              disabled={submitting}
              type="submit"
              fullWidth
              variant="default"
            >
              {t("login")}
            </Button>

          </Form>

          <Box height={60} mt={2}>

            {submitting && <LoadingSpinner
              forwardedRef={undefined}
              status="loading"
              text="lataa"
              textAlign="right"
              variant="normal"
            />
            }
            <Grid container maxWidth="xs">
              <Grid item>
                {actionData && actionData.datetime > hideErrorDateTime && actionData.error && <Notification
                  closeText="Sulje"
                  headingText={t("loginView.failed")}
                  status="error"
                  onCloseButtonClick={() => { setHideErrorDateTime(new Date()) }}
                >
                  {actionData.error}
                </Notification>
                }
              </Grid>

            </Grid>
          </Box>
        </Box>
      </Box>

    </>
  );
}


export default LoginView
