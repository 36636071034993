import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Outlet, useActionData, useNavigate, useNavigation } from 'react-router-dom'
import { Block, Button, Heading, IconBuildingAdministrative, LoadingSpinner, Paragraph, Text, TextInput } from 'suomifi-ui-components'
import { WalletsAPI } from '../api/wallets-api/wallets-api'
import { Box, FormControlLabel, FormGroup, Paper, Switch } from '@mui/material'
import { Etrc } from '../models/etrc'
import { t } from 'i18next'

type Props = { issuerDid: string, vc: Etrc }

const PANKKI_API_URL = process.env.REACT_APP_PANKKI_API_URL || process.env.PANKKI_API_URL || "/api";
const PANKKI_API_KEY = process.env.REACT_APP_PANKKI_API_KEY || process.env.PANKKI_API_KEY || "";


interface IActionData {
  message: string|undefined,
  success: boolean|undefined,
  error: string|undefined,
  requestId: string|undefined
}

const EtrcView = (props: Props) => {
  const [visualize, setVisualize] = useState<boolean>(true);
  const vc = props.vc;
  debugger;
  const naturalPerson = undefined //vc?.credentialSubject?.TODO
  const legalEntity = vc?.credentialSubject?.taxpayerwhosresidencyiscertified?.taxpayerEntity;
  const legalidentifier = legalEntity?.legalidentifier;
  const registeredAddress = legalEntity?.registeredAddress;

  const dateOfIssuance = new Date(vc?.credentialSubject?.dateOfIssue || vc?.issuanceDate||new Date());// || legalidentifier?.dateOfIssue ||new Date());
  //const dateOfIssuance = new Date(vc?. || legalidentifier?.dateOfIssue ||new Date());
  const coveredPeriodStart = new Date(vc?.credentialSubject?.coveredPeriod?.startTime || new Date(dateOfIssuance.getFullYear()-1,0,1));
  const coveredPeriodEnd = new Date(vc?.credentialSubject?.coveredPeriod?.endTime || new Date(coveredPeriodStart.getFullYear(),11,31));
  const country2 = registeredAddress?.adminUnitLevel1 === "FI" ? "Finland" : registeredAddress?.adminUnitLevel1;
  const country = vc?.credentialSubject?.residency?.name || country2;
  return (
    <div>
      { vc &&
      <FormGroup>
        <FormControlLabel 
          control={<Switch checked={visualize} 
          onChange={() => setVisualize(old => !old)} />} 
          label={t("credentialView.visualize")} 
          labelPlacement="start" 
        />
      </FormGroup>
      }
      { vc && !visualize &&
        <p>{JSON.stringify(vc)}</p>
      }
      { vc && visualize &&
        <div style={{ paddingTop: "30px" }}>
          <Text variant="bold">ANNEX TO THE STEERING NOTE</Text>
          <div style={{float: "right", display: "flex", alignItems: "center"}}>
        
            <IconBuildingAdministrative ></IconBuildingAdministrative>
            <Text>Mini-Vero</Text>
            
          </div>
          <Heading variant='h1'>DIGITAL TAX RESIDENCE CERTIFICATE</Heading>
            <div style={{textAlign: "center"}}>
            <Text variant="bold">INFORMATION ON THE TAXPAYER</Text>
            </div>
            <table className='certificate-table' cellSpacing={0}>
              <tbody>
                <tr>
                  <td style={{width: "50%"}}><b>NATURAL PERSON</b></td><td style={{minWidth: "60px"}}></td><td>YES{naturalPerson && <b>&nbsp;&nbsp;&nbsp;X&nbsp;&nbsp;&nbsp;</b>}</td><td>NO{legalEntity && <b>&nbsp;&nbsp;&nbsp;X&nbsp;&nbsp;&nbsp;</b>}</td><td style={{minWidth: "60px"}}></td>
                </tr>
                <tr>
                  <td>Name and Surname</td><td colSpan={4}></td>
                </tr>
                <tr>
                  <td>Date and place of birth</td><td colSpan={4}></td>
                </tr>
                <tr>
                  <td>Address</td><td colSpan={4}></td>
                </tr>
                <tr>
                  <td>TIN</td><td colSpan={4}></td>
                </tr>
                <tr>
                  <td><b>ENTITY</b></td><td colSpan={4}></td>
                </tr>
                <tr>
                  <td>Name</td><td colSpan={4}>{ legalEntity?.legalName}</td>
                </tr>
                <tr>
                  <td>Address</td><td colSpan={4}>
                    <Paragraph>{ registeredAddress?.fullAddress }</Paragraph>
                    <Paragraph>{ registeredAddress?.addressArea }</Paragraph>
                    <Paragraph>{ registeredAddress?.postName }</Paragraph>
                    
                    <Paragraph>{ registeredAddress?.poBox }</Paragraph>
                    <Paragraph>{ registeredAddress?.postName } { registeredAddress?.postCode }</Paragraph>
                    <Paragraph>{ registeredAddress?.adminUnitLevel1 === "FI" ? "FINLAND" : registeredAddress?.adminUnitLevel1 }</Paragraph>
                  </td>
                </tr>
                <tr>
                  <td>TIN</td><td colSpan={4}>{legalEntity?.legalidentifier?.notation}</td>
                </tr>
                <tr>
                  <td>EUID</td><td></td><td colSpan={2}>where applicable</td>
                  <td>{""}</td>
                </tr>
              </tbody>
            </table>
            <div style={{textAlign: "center"}}>
              <Text variant="bold">CERTIFIED INFORMATION</Text>
            </div>

            <table className='certificate-table' cellSpacing={0}>
              <tbody>
                <tr>
                  <td style={{width: "50%"}}>The tax Authorities of</td><td>{country}</td>
                </tr>
                <tr>
                  <td>Certifies to the best of their knowledge based on the available information, that the above mentioned taxpayer is resident of</td><td>{country}</td>
                </tr>
                <tr>
                  <td>Covered period</td><td>{coveredPeriodStart.toLocaleDateString(/*selectedLanguage*/)} – {coveredPeriodEnd.toLocaleDateString(/*selectedLanguage*/)}</td>
                </tr>
                <tr>
                  <td>Date of issuance</td><td>{dateOfIssuance.toLocaleDateString()}</td>
                </tr>
                <tr>
                  <td>Relevant Double Tax Treaty</td><td>{ vc?.credentialSubject?.relevantDoubleTaxTreaty || ""}</td>
                </tr>
                <tr>
                  <td>Additional information [necessary for proving the taxpayer's residence for tax purposes insofar as the certificate is not to be used for relief or withholding tax within the EU based on the available information]</td>
                  <td>{ vc?.credentialSubject?.additionalInformation || ""}</td>
                </tr>
              </tbody>
            </table>
            <div style={{textAlign: "center"}}>
              <Text variant="bold">SHORT NOTICE ABOUT THE VERIFICATION METHOD</Text>
            </div>
            <table className='certificate-table' cellSpacing={0}>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
            <br/><br/>
            <table cellSpacing={0}>
              <tbody>
                <tr>
                  <td style={{verticalAlign: "center"}}>Date of issuance</td><td style={{width: "40px"}}></td>
                  <td style={{verticalAlign: "center"}}>E-signature</td>
                </tr>
                <tr>
                  <td>
                    <div style={{borderBottom: "1px solid black"}}>{ new Date(vc.issuanceDate||new Date()).toLocaleDateString(/*selectedLanguage*/)}</div>
                  </td>
                  <td></td>
                  <td><div style={{borderBottom: "1px solid black"}}><a href={legalidentifier?.issuingAuthorityURI||'#'} rel="noreferrer" target="_blank" title={legalidentifier?.issuingAuthorityName}>{vc.issuer}</a></div></td>
                </tr>
              </tbody>
            </table>


        </div>  

      }
    </div>
  )
}


export default EtrcView