import { Trans, useTranslation } from 'react-i18next'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { Block, Button, Heading, Text, IconLogin,  IconLogout,  IconMoney,  LanguageMenu,  LanguageMenuItem,  Paragraph, IconArrowRight } from 'suomifi-ui-components'
import { Box } from '@mui/material'
import Breadcrumbs from '../components/Breadcrumbs'
import { useUserContext } from '../context/UserContext'
import Footer from '../components/Footer'
import Header from '../components/Header'

function Home() {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const isLoginPage = location.pathname === "/login";

  const userContext = useUserContext();

  const isLoggedIn = userContext.isLoggedIn;
  const name = userContext.name;

  return (
    <>
      <Header></Header>
      <Block variant='main'>
        <Block variant='section'>
          {location.pathname === "/" &&
            <>
              <Heading variant="h1">{t('welcome')}</Heading>
              <Paragraph>
                <Trans i18nKey="homeView.pageDescription">
                  Tämä ei ole oikean pankin sivusto, tämä sivusto on luotu <Link to={ t('ydUrl')}>yrityksen digitalous</Link> kokeiluja varten.
                </Trans>
              </Paragraph>
              { isLoggedIn ?
                <>
                  <br/>
                  <Link to="/kyc">
                    <Button iconRight={<IconArrowRight></IconArrowRight>}>{t("homeView.kycButton")}</Button>
                  </Link>
                  <br/><br/>
                  <Link to="/kyc-old">
                    <Button iconRight={<IconArrowRight></IconArrowRight>}>{t("homeView.kycButton") + " (Walt.id)"}</Button>
                  </Link>
                  <br/>
                  <br/>
                  <Link to="/eudtrc">
                    <Button iconRight={<IconArrowRight></IconArrowRight>}>{t("homeView.etrcButton")}</Button>
                  </Link>
                </>
                :
              <Paragraph>{t('homeView.pageInfo')}</Paragraph> 
              }
            </>
          }
          <Outlet></Outlet>
        </Block>
      </Block>
      <Footer />
    </>
  )
}

export default Home