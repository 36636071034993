import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Outlet, useActionData, useLocation, useNavigate, useNavigation } from 'react-router-dom'
import { Block, Button, Heading, LoadingSpinner, Paragraph, TextInput } from 'suomifi-ui-components'
import { WalletsAPI } from '../api/wallets-api/wallets-api'
import { Box } from '@mui/material'
import QRCode from 'react-qr-code'


type Props = {}

const PANKKI_API_URL = process.env.REACT_APP_PANKKI_API_URL || process.env.PANKKI_API_URL || "/api";
const PANKKI_API_KEY = process.env.REACT_APP_PANKKI_API_KEY || process.env.PANKKI_API_KEY || "";


interface IActionData {
  message: string|undefined,
  success: boolean|undefined,
  error: string|undefined,
  requestId: string|undefined
  openid4vp: string|undefined
}

const EudtrcView = (props: Props) => {
  const navigate = useNavigate();
  const [ openId4Vp, setOpenId4Vp] = useState<string|undefined>(undefined);
  const [ finalResult, setFinalResult ] = useState<string|null>(null);
  const [ pollCounter, setPollCounter ] = useState<number>(0);
  const { t } = useTranslation(); 
  const navigation = useNavigation();
  const data = useActionData() as IActionData;
  const loading = navigation.state === "loading";
  const submitting = navigation.state === "submitting";
  

  if (data?.openid4vp) {
    if (data.openid4vp !== openId4Vp)
      setOpenId4Vp(data.openid4vp);
  }

  useEffect(() => {
    if (data?.openid4vp) {
      //setOpenId4Vp(data.openid4vp);
      console.log("polling...");
      
      const walletsApi = new WalletsAPI({ baseUrl: PANKKI_API_URL, baseApiParams: { headers: { "x-functions-key": PANKKI_API_KEY }}}); // REACT_APP_PANKKI_API_KEY added to devops build
      console.log("pollPresentationRequest");

      if (finalResult) {
        // setTimeout(() => {
        //   //walletsApi.pankki.
        // }, 2000);
      }
      else {
        // Wait two seconds before polling...
        
        setTimeout(() => {
          walletsApi.pankki.pollPresentationRequest({ requestId: data.requestId! }).then(result => {
            try {
              if (result && result.ok) {
                result.text().then(text => {
                  //return redirect("/user/success")
                  if (text === "True") {
                    setFinalResult(text);
                  //if (text.indexOf("?") >= 0) {
                    //const params = text.substring(text.indexOf("?"));
                    navigate({
                      pathname: '/eudtrc/success',
                      search: `access_token=${data.requestId}`,
                    });
                  }
                  else {
                    setPollCounter(pollCounter+1); 
                  }
                  
                  //setFinalResult(text);
                }); 
              }
              else {
                setPollCounter(pollCounter+1); 
              }         
            } catch {
              alert(result);
            }
          }).catch((err) => {
            console.log(err);
          });
        }, 2500);

      }
      
      
    }
    
  }, [data, pollCounter, finalResult]);

  return (
    <Block>
      <Heading variant={'h1'}>{t("eudtrcView.title")}</Heading>
      <Paragraph>{t("eudtrcView.description")}</Paragraph>
      <Form method='post'>

        <Button disabled={submitting||loading||data?.success} variant="default" type='submit'>{t("eudtrcView.requestPresentation")}</Button>
        

        {/* <TextInput readOnly={true} name='companyCode' labelText={t("eudtrcView.companyCode")} defaultValue="7011367-6"></TextInput> */}
        <TextInput readOnly={false} className='longer-input' name='walletAddress' labelText={t("eudtrcView.walletAddress")} defaultValue="did:web:yrityslompakko.minisuomi.net:7011367-6"></TextInput>
    

        <Button disabled={submitting||loading||data?.success} variant="default" type='submit'>{t("eudtrcView.connect")}</Button>
        
        { openId4Vp && !finalResult &&
        <div style={{ height: "auto", margin: "0 auto", maxWidth: 256, width: "100%" }}>
          <a href={openId4Vp}>
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={ openId4Vp }
              viewBox={`0 0 256 256`}
              />
            </a>  
          </div> 
        }

        <Box mt={1}>
          { (loading || submitting || data?.success === true || data?.success === false) &&   
          <LoadingSpinner textAlign='right' text={ t(data?.success ? "eudtrcView.success" : "sending")} status={data?.success ? "success" : (data?.success === false ? "failed" : undefined)}></LoadingSpinner> 
          }
        </Box>

        { data?.success === true && finalResult === null &&
          <Box mt={1}>
            <LoadingSpinner textAlign='right' text={ t("eudtrcView.pollCertificate") }></LoadingSpinner> 
        
          </Box>
        } 
        <Outlet></Outlet>
      </Form>
     
      
    </Block>
  )
}

export const startPresentationRequest = async ({ request }: any) => {
  
  
  const data = Object.fromEntries(await request.formData());
  

  try {
    //const delay = (ms:any) => new Promise(res => setTimeout(res, ms));
    //await delay(500); // TODO remove delay and do real login here: const result = await axios.post<any>("/login", data);//.then((res) => { });
  
    // TODO/NOTE do not do this on real production site with real data, it reveals api key on the browser query
    const walletsApi = new WalletsAPI({ baseUrl: PANKKI_API_URL, baseApiParams: { headers: { "x-functions-key": PANKKI_API_KEY }}}); // REACT_APP_PANKKI_API_KEY added to devops build
    debugger;
    const response = await walletsApi.pankki.startEudtrcPresentationRequestWithWebDid({ webDid: data.walletAddress });
    const openid4vp = await response.text();
    const state = new URL(openid4vp).searchParams.get("state");
    debugger;
    return { message: "Pyyntö lähetetty", requestId: state, openid4vp: openid4vp, success: true } as IActionData;
  }
  catch (err: any) {
    return { error: err?.error?.message || err?.message || "failed" } as IActionData
  }
}


export default EudtrcView