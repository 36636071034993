import React, { useState } from 'react'
import { Heading } from 'suomifi-ui-components';
import { FormControlLabel, FormGroup, Paper, Switch } from '@mui/material';
import { t } from 'i18next';
import convertToArray from './convertToArray';


export interface EuccCredentialSdJwt {
  iss: string
  legalName: string
  legalFormType: string
  legalIdentifier: string
  registeredAddress: RegisteredAddress[]
  postalAddress: PostalAddress[]
  registrationDate: string
  shareCapital: ShareCapital[]
  legalEntityStatus: string
  legalRepresentative: LegalRepresentative[]
  legalEntityActivity: LegalEntityActivity[]
  contactPoint: ContactPoint[]
  issuer_name: string
  issuer_id: string
  issuer_country: string
  issuance_date: string
  expiry_date: string
  authentic_source_id: string
  authentic_source_name: string
}

export interface RegisteredAddress {
  post_code: string
  post_name: string
  thoroughfare: string
  locator_designator: string
}

export interface PostalAddress {
  post_code: string
  post_name: string
  thoroughfare: string
  locator_designator: string
}

export interface ShareCapital {
  amount: string
  currency: string
}

export interface LegalRepresentative {
  role: string
  legalEntityId: string
  scopeOfRepresentation: string,
  family_name?: string,
  given_name?: string,
  birth_date?: string
}

export interface LegalEntityActivity {
  code: string
  businessDescription: string
}

export interface ContactPoint {
  contactPage: string
  hasEmail: string
  hasTelephone: string
}


type Props = { 
  credential: EuccCredentialSdJwt
}

const Eucc = (props: Props) => {
  const [visualize, setVisualize] = useState<boolean>(true);
  const vc = props.credential;

  return (
    <Paper style={{paddingTop: "30px", paddingBottom: "50px" }}>

      <FormGroup>
        <FormControlLabel 
          control={<Switch checked={visualize} 
          onChange={() => setVisualize(old => !old)} />} 
          label={t("credentialView.visualize")} 
          labelPlacement="start" 
        />
      </FormGroup>

      { !visualize ? <pre>{JSON.stringify(vc, null, 2)}</pre> :
      <div>
        <p>
          Issuer: { vc.iss }<br/>
          {/* Issuer Business ID: { vc.legalIdentifier.notation }<br/> */}
          Date and time of creating the document: { vc.issuance_date }<br/>
        </p>
        <Heading variant='h3'>EU Company Certificate (EUCC)</Heading>
        <br/>
        
        
        <table cellSpacing={0} style={{textAlign: "left"}}>
          <tbody>
          <tr>
            <th colSpan={3}>
              <Heading variant='h4' style={{borderBottom: "1px solid black", display: "inline"}}>Identifiers</Heading>
            </th>
          </tr>
          <tr>
            <td title="Name of the company">Company name</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.legalName}</td>
          </tr>
          {/* <tr>
            <td title="Finnish Business Identity Code">Business ID</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.notation}</td>
          </tr> */}
          <tr>
            <td title="EU Business Identity Code">EUID</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.legalIdentifier}</td>
          </tr>
          <tr>
            <td title="Date on which the PRH has made the register entry">Registration date</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.registrationDate}</td>
          </tr>
          <tr>
            <td>Company type</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{ vc.legalFormType }</td>
          </tr>
          <tr>
            <td title="Postal address">Contact details:</td>
            <td style={{minWidth: "20px"}}></td>
            <td></td>
          </tr>
          {/* {vc.postalAddress.poBox && 
            <tr>
              <td title="Postal address">Postall address</td>
              <td style={{minWidth: "20px"}}></td>
              <td>PL {vc.postalAddress.poBox} {vc.postalAddress.postName}</td>
            </tr>
          } */}
          { convertToArray(vc.postalAddress)?.length &&
          <tr>
            <td title="Street address">Street address</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{convertToArray(vc.postalAddress)[0].post_code + " " + convertToArray(vc.postalAddress)[0].post_name}</td>
          </tr>
          }

          <tr><td><br/></td></tr>
          {/* <tr>
            <th>
              <Heading variant='h4' style={{borderBottom: "1px solid black", display: "inline"}} title="Domicile (municipality where the company is managed, place of registered office)">Registered office</Heading>
            </th>
            <td></td>
            <td>{vc.registeredOffice.postName} {vc.registeredOffice.adminUnitL1}</td>
          </tr> */}
        
          <tr><td><br/></td></tr>
          <tr>
            <th colSpan={3}>
              <Heading variant='h4' style={{borderBottom: "1px solid black", display: "inline"}}>Share capital</Heading>
            </th>
          </tr>

          { vc.shareCapital && convertToArray<ShareCapital>(vc.shareCapital).map((shareCapital, i) => 
            <tr key={i}>
              <td title="Amount of share capital">Share capital</td>
              <td style={{minWidth: "20px"}}></td>
              <td>{ shareCapital.amount } { shareCapital.currency }</td>
            </tr>)
          }
          </tbody>
        </table>

        {/* <Heading variant='h4' style={{borderBottom: "1px solid black", display: "inline"}}>Financial period</Heading>
        <table>
          <tr>
            <td>Financial period</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.durationOfTheLegalEntity}</td>  01.01 - 31.12
          </tr>
        </table> */}

        <br/>
        <table cellSpacing={0} width={"100%"} style={{textAlign: "left"}}>
          <tbody>
          <tr>
            <th colSpan={4}>
              <Heading variant='h4' style={{borderBottom: "1px solid black", display: "inline"}}>Persons</Heading>
            </th>
          </tr>
          <tr>
            <th>role</th>
            <th>surname, first name(s) / company name</th>
            <th>date of birth / identity code</th>
            <th>citizenship</th>
          </tr>
          { vc.legalRepresentative && convertToArray<LegalRepresentative>(vc.legalRepresentative).map((legalReprestative, i) => 
             <tr key={legalReprestative.legalEntityId}>
              <td>{ legalReprestative.role }</td>
              <td>{ legalReprestative.family_name } { legalReprestative.given_name }</td>
              <td>{ legalReprestative.birth_date } / { legalReprestative.legalEntityId }</td>
              <td>
                Citizen of ?
              </td>
            </tr>)
          }

          </tbody>
        </table>
        <br/>
        <table cellSpacing={0} style={{textAlign:"left"}}>
          <tbody>
          <tr>
            <th>
              <Heading variant='h4' style={{borderBottom: "1px solid black", display: "inline"}}>Name history</Heading>
            </th>
          </tr>
          <tr>
            <td>{vc.legalName} {vc.registrationDate} - present</td>
          </tr>
          <tr>
            <td><p>Source of information: { vc.issuer_name }</p></td>
          </tr>
          </tbody>
        </table>

        <br/>
        <table cellSpacing={0}>
          <tbody>
            <tr>
              <td style={{verticalAlign: "center"}}>Date of issuance</td><td style={{width: "40px"}}></td>
              <td style={{verticalAlign: "center"}}>E-signature</td>
            </tr>
            <tr>
              <td>
                <div style={{borderBottom: "1px solid black"}}>{ vc.registrationDate ||new Date().toLocaleDateString(/*selectedLanguage*/)}</div>
              </td>
              <td></td>
              <td><div style={{borderBottom: "1px solid black"}}><a href="https://prh.minisuomi.net" rel="noreferrer" target="_blank" title={vc.legalName}></a></div></td>
            </tr>
          </tbody>
        </table>
      </div> 
      }
    </Paper>
  )
}

export default Eucc