import { useEffect, useState } from 'react';
import './App.css';

import { ThemeProvider, createTheme } from '@mui/material';
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { IUserData, UserContext } from './context/UserContext';
import LoginView from './views/login/LoginView';
import EudtrcView, { startPresentationRequest } from './views/EudtrcView';
import Home from './views/Home';
import LogoutView from './views/login/LogoutView';
import CredentialView from './views/CredentialView';
import KycView from './views/KycView';
import { loginPostAction } from './views/login/LoginPostAction';
import SuomiFiAfterAuthView from './views/login/SuomiFiAfterAuthView';
import { useTranslation } from 'react-i18next';
import KycViewWaltId, { startKycWaltIdPresentationRequests } from './views/KycViewWaltId';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(0, 53, 122)'
    }
  },
  typography: {
    h1: { fontSize: '2em', fontWeight: 'bold', margin: '0.75em 0 0.75em 0' },
    h2: { fontSize: '1.75em', fontWeight: 'bold', margin: '0.75em 0 0.75em 0' },
    h3: { fontSize: '1.5em', fontWeight: 'bold', margin: '0.75em 0 0.75em 0' },
    h4: { fontSize: '1.25em', fontWeight: 'bold', margin: '0.75em 0 0.75em 0' },
    fontFamily: '"Source Sans Pro", "Helvetica Neue", Arial, sans-serif',
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          border: '1px solid #ccc',
          padding: '5em 3em 1em 3em'
        }
      },
      defaultProps: {
        elevation: 0,
        square: true,
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: '1px solid #ccc',
          padding: '1em 1em 1em 1em'
        }
      }
    }
  }
});

const userContextContainer: {
  userContext: IUserData|undefined;
} = {
  userContext: undefined
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    handle: "route.home",
    children: [
      {
        path: "suomifi",
        element: <SuomiFiAfterAuthView />
      },
      {
        path: "login",
        element: <LoginView />,
        action: loginPostAction(userContextContainer)
      },
      {
        path: "logout",
        element: <LogoutView />
      },
      {
        path: "eudtrc",
        element: <EudtrcView />,
        handle: "route.eudtrc",
        action: startPresentationRequest,
        children: [
          {
            path: "success",
            element: <CredentialView />
          }
        ]
      },
      {
        path: "kyc",
        element: <KycView />,
        handle: "route.kyc",
        // children: [
        //   {
        //     path: "success",
        //     element: <KycView />
        //   }
        // ]
      },
      {
        path: "kyc-old",
        element: <KycViewWaltId />,
        handle: "route.kyc",
        action: startKycWaltIdPresentationRequests,
        // children: [
        //   {
        //     path: "success",
        //     element: <KycView />
        //   }
        // ]
      }
    ],
  },
]);



function App() {

  const [companyId, setCompanyId] = useState<string|null>(localStorage.getItem("companyId"));
  const [company, setCompany] = useState<string|null>(localStorage.getItem("company"));
  const [name, setName] = useState<string|null>(localStorage.getItem("user"));
  // const [role, setRole] = useState<string|null>(localStorage.getItem("role"));
  // const temp = localStorage.getItem("authorizationRoles");
  // const suomiFiRoles = !temp ? [] : JSON.parse(temp) as SuomiFiAuthorizationRole[];
  // const [authorizationRoles, setAuthorizationRoles] = useState<SuomiFiAuthorizationRole[]>(suomiFiRoles);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(name!==null);
  const { t } = useTranslation();
  
  userContextContainer.userContext = { /*role: role, setRole: setRole, authorizationRoles: authorizationRoles, setAuthorizationRoles: setAuthorizationRoles,*/ isLoggedIn: isLoggedIn, setIsLoggedIn: setIsLoggedIn, setName: setName, name: name, company, setCompany, companyId, setCompanyId};
  
  //[isLoggedIn, name, company]);
  useEffect(() => {
    console.log("App.tsx useEffect");
    const user = localStorage.getItem("user");
    const company = localStorage.getItem("company");
    const companyId = localStorage.getItem("companyId");
    // const role = localStorage.getItem("role");
    // const suomiFiRoles = localStorage.getItem("authorizationRoles")||"[]";
    // const authorizationRoles: SuomiFiAuthorizationRole[] = JSON.parse(suomiFiRoles);
    // TODO use single setAuth() ?
    setName(user);
    setCompany(company);
    setCompanyId(companyId);
    // setRole(role);
    // setAuthorizationRoles(authorizationRoles);
    setIsLoggedIn(user !== null);
  }, []);

  useEffect(() => {
    document.title = t("appName");
  },[t])

  
  return (
    <ThemeProvider theme={theme}>
      <UserContext.Provider value={userContextContainer.userContext}>
        <RouterProvider router={router} />
      </UserContext.Provider>
    </ThemeProvider>
  );
}


export default App;