import { ActionFunction, redirect } from 'react-router-dom';
import { IActionData } from './LoginView';
import { IUserData } from '../../context/UserContext';

export const loginPostAction = (userContextContainer: { userContext: IUserData|undefined }): ActionFunction => async ({ request }): Promise<Response | IActionData> => {
  
  const data = Object.fromEntries(await request.formData());
  const username = data.email as string;
  //const username = (data.email as string).split("@")[0].split(".").map(x => x && x.length > 1 ? x[0].toUpperCase() + x.substring(1) : "").join(" ").replace("  ", " ");

  //const password = (data.password as string)


  try {
    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
    await delay(500); // TODO remove delay and do real login here: const result = await axios.post<any>("/login", data);//.then((res) => { });

    debugger;
    //const response = await vceApi!.holders.login(username, password);
    //const foo = await response.json()
    //console.log(foo?.csrfToken);

    
    // localStorage.setItem("user", username);
    // localStorage.setItem("company", "Fake Company");
    //localStorage.setItem("companyId", "1234567-8");
    //localStorage.setItem("refreshTime", (new Date().getTime()/1000).toString());

    localStorage.setItem("user", username);
    localStorage.setItem("refreshTime", (new Date().getTime()/1000).toString());

    userContextContainer.userContext?.setName(username);
    userContextContainer.userContext?.setIsLoggedIn(true);

    const searchParams = new URLSearchParams(document.location.search)
    const redirectpath = searchParams.get("redirect");
    debugger;
    //window.location.href = window.location.origin + "/";//redirectpath?.startsWith("/") ? window.location.origin + "/" redirectpath : "/";
    return redirect(redirectpath?.startsWith("/") ? redirectpath : "/");
  }
  catch (err: unknown) {
    if (err instanceof Error) {
      return { error: err.message, datetime: new Date() } as IActionData
    }
    return { error: typeof(err) === "string" ? err : "Unknown error" } as IActionData
  }
}
