import React, { useState } from 'react'
import { Agent3 } from '../models/agent3';
import { FormControlLabel, FormGroup, Paper, Switch } from '@mui/material';
import { Heading, Text } from 'suomifi-ui-components'
import { t } from 'i18next';


type Props = { issuerDid: string, vc: Agent3 }

const Agent3View = (props: Props) => {
  const vc = props.vc;
  debugger;
  const issuerDid = props.issuerDid; 
  const [visualize, setVisualize] = useState<boolean>(true);
  return (
    //<Paper style={{ paddingTop: "30px" }}>
    <div style={{ paddingTop: "30px" }}>
      <FormGroup>
        <FormControlLabel 
          control={<Switch checked={visualize} 
          onChange={() => setVisualize(old => !old)} />} 
          label={t("credentialView.visualize")} 
          labelPlacement="start" 
        />
      </FormGroup>

      { !visualize ? <pre>{JSON.stringify(vc, null, 2)}</pre> :
      <div>
        Finnish Patent and Registration (Mini-PRH demo)<br/>
        Office<br/>
        Trade register<br/>
        Mini-PRH, FINLAND

        <Heading variant="h1">DETAILS OF THE BENEFICIARY REGISTRY EXTRACT</Heading>
        Company name: {vc.name}
        {/* Business id: {vc.notation} */}
        {/* The company is registered: {vc.company.registrationDate} */}
        
        <br />
        <br />
        <hr />
        <br />
        <Text>ACTUAL BENEFICIAL OWNERS (Registered { vc.registrationDate }</Text>
        <br/>
        { vc.beneficialowners && vc.beneficialowners.map(beneficialOwner => 
          <div>
            {beneficialOwner.naturalPerson.fullName}<br/>
            Grounds and extend:<br/>
            { beneficialOwner.interestType }: {beneficialOwner.interestControl}
            <br/><br/>
          </div>
        )}
        {/* <br /> */}
        VALID PERSONAL DETAILS:<br/>

        { vc.beneficialowners && vc.beneficialowners.map(beneficialOwner => 
          <div>
            {beneficialOwner.naturalPerson.fullName} { beneficialOwner.naturalPerson.citizenship.name === "Finnish" ? "Citizen of Finland" : beneficialOwner.naturalPerson.citizenship.name }
            <br/>
          </div>
        )}
        <br/>
        COMPANY NAME HISTORY:<br/>
        {vc.name} 1.1.2020 12:00 -
        <br/><br/>
        <hr/>
        <br/>
        Source of information: Finnish Patent and Registration Office (Mini-PRH demo)
        {/* <table>
          <tbody>
          { vc.beneficialowners && vc.beneficialowners.map(beneficialOwner => 
            <tr key={beneficialOwner.naturalPerson.fullName }>
              <td>{ beneficialOwner.interestType }</td>
              <td>{ beneficialOwner.interestControl }</td>
              <td>{ beneficialOwner.naturalPerson.fullName }</td>
              <td>{ beneficialOwner.naturalPerson.residency.name }</td>
              <td>{ beneficialOwner.naturalPerson.citizenship.name }</td>
            </tr>
          )}
          </tbody>
          </table> */}

      </div>
      }  
    </div>
  )
}

export default Agent3View;
