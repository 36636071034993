import React, { useEffect, useState, useTransition } from 'react'
import { useSearchParams } from 'react-router-dom'
import { VcRoot, WalletsAPI } from '../api/wallets-api/wallets-api'
import { FormControlLabel, FormGroup, Paper, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Block, Heading, IconBuildingAdministrative, Paragraph, Text } from 'suomifi-ui-components';
import { debug } from 'console';

const PANKKI_API_URL = process.env.REACT_APP_PANKKI_API_URL || process.env.PANKKI_API_URL || "/api";
const PANKKI_API_KEY = process.env.REACT_APP_PANKKI_API_KEY || process.env.PANKKI_API_KEY || "";

type Props = {
  //accessToken: string|undefined
}

// TODO refactor this, remove and start using EtrcView?
// This is view for single Eudtrc view, 
// After implementation the model renamed to ETRC
// And we wanted to replace xbrlje view with this one, so same vc stuff copied to etrc.ts
const CredentialView = (props: Props) => {
  const [searchParams] = useSearchParams() // access_token?
  const [vc, setVc] = useState<VcRoot|undefined>(undefined);
  debugger;
  const accessToken = searchParams.get("access_token");
  const { t, i18n } = useTranslation();
  const selectedLanguage = i18n.resolvedLanguage || "fi";




  const [visualize, setVisualize] = useState<boolean>(true);

  useEffect(() => {
    if (accessToken?.match(/^[a-zA-Z0-9_+-]+$/)) {
      // TODO avoid copying walletsApi every where, export/import it
      const walletsApi = new WalletsAPI({ baseUrl: PANKKI_API_URL, baseApiParams: { headers: { "x-functions-key": PANKKI_API_KEY }}}); // REACT_APP_PANKKI_API_KEY added to devops build
      // if (!requested) {}
      // setRequested(true);
      
      if (sessionStorage.getItem("eudtrcAccessToken") !== accessToken)
      {
        // TODO figure out why it calls twice...
        sessionStorage.setItem("eudtrcAccessToken", accessToken);
        // walletsApi.pankki.getVerifiedCertificateByState({ requestId: accessToken }).then(data => data.json().then((vcs: Vc[]) => {
        //   if (vcs.length > 0) {
        //     setVc(vcs[0]);
        //   }
        // }))          

        walletsApi.pankki.getVerifiedCertificateByState({ requestId: accessToken }).then(x => {
          if (x.data.length > 0) {
            debugger;
            const latest = x.data.sort((a, b) => {
              const dataA = new Date(a.issuanceDate||new Date());
              const dataB = new Date(b.issuanceDate||new Date());
              return dataA > dataB ? -1 : 1;
            })[0];
            setVc(latest);
          }
          
        });
            
      }
      // fetch("https://waltid.minisuomi.net/verifier-api/pankki/auth?access_token=" + accessToken)
      //   .then(response => response.json())
      //   .then(data => {
      //     setVc(data);
      // });

        
    }
  }, [accessToken]);

  const naturalPerson = undefined //vc?.credentialSubject?.TODO
  const legalEntity = vc?.credentialSubject?.taxpayerwhosresidencyiscertified?.taxpayerEntity;
  const legalidentifier = legalEntity?.legalidentifier;
  const registeredAddress = legalEntity?.registeredAddress;

  const dateOfIssuance = new Date(vc?.credentialSubject?.dateOfIssue || vc?.issuanceDate||new Date());// || legalidentifier?.dateOfIssue ||new Date());
  //const dateOfIssuance = new Date(vc?. || legalidentifier?.dateOfIssue ||new Date());
  const coveredPeriodStart = new Date(vc?.credentialSubject?.coveredPeriod?.startTime || new Date(dateOfIssuance.getFullYear()-1,0,1));
  const coveredPeriodEnd = new Date(vc?.credentialSubject?.coveredPeriod?.endTime || new Date(coveredPeriodStart.getFullYear(),11,31));
  const country2 = registeredAddress?.adminUnitLevel1 === "FI" ? "Finland" : registeredAddress?.adminUnitLevel1;
  const country = vc?.credentialSubject?.residency?.name || country2;
  return (
    <div>
      { vc &&
      <FormGroup>
        <FormControlLabel 
          control={<Switch checked={visualize} 
          onChange={() => setVisualize(old => !old)} />} 
          label={t("credentialView.visualize")} 
          labelPlacement="start" 
        />
      </FormGroup>
      }
      { vc && !visualize &&
        <p>{JSON.stringify(vc)}</p>
      }
      { vc && visualize &&
        <Paper style={{ paddingTop: "30px" }}>
          <Text variant="bold">ANNEX TO THE STEERING NOTE</Text>
          <div style={{float: "right", display: "flex", alignItems: "center"}}>
        
            <IconBuildingAdministrative ></IconBuildingAdministrative>
            <Text>Mini-Vero</Text>
            
          </div>
          <Heading variant='h1'>DIGITAL TAX RESIDENCE CERTIFICATE</Heading>
            <div style={{textAlign: "center"}}>
            <Text variant="bold">INFORMATION ON THE TAXPAYER</Text>
            </div>
            <table className='certificate-table' cellSpacing={0}>
              <tbody>
                <tr>
                  <td style={{width: "50%"}}><b>NATURAL PERSON</b></td><td style={{minWidth: "60px"}}></td><td>YES{naturalPerson && <b>&nbsp;&nbsp;&nbsp;X&nbsp;&nbsp;&nbsp;</b>}</td><td>NO{legalEntity && <b>&nbsp;&nbsp;&nbsp;X&nbsp;&nbsp;&nbsp;</b>}</td><td style={{minWidth: "60px"}}></td>
                </tr>
                <tr>
                  <td>Name and Surname</td><td colSpan={4}></td>
                </tr>
                <tr>
                  <td>Date and place of birth</td><td colSpan={4}></td>
                </tr>
                <tr>
                  <td>Address</td><td colSpan={4}></td>
                </tr>
                <tr>
                  <td>TIN</td><td colSpan={4}></td>
                </tr>
                <tr>
                  <td><b>ENTITY</b></td><td colSpan={4}></td>
                </tr>
                <tr>
                  <td>Name</td><td colSpan={4}>{ legalEntity?.legalName?.length ? legalEntity?.legalName[0] : ""}</td>
                </tr>
                <tr>
                  <td>Address</td><td colSpan={4}>
                    <Paragraph>{ registeredAddress?.fullAddress }</Paragraph>
                    <Paragraph>{ registeredAddress?.addressArea }</Paragraph>
                    <Paragraph>{ registeredAddress?.postName }</Paragraph>
                    
                    <Paragraph>{ registeredAddress?.poBox }</Paragraph>
                    <Paragraph>{ registeredAddress?.postName } { registeredAddress?.postCode }</Paragraph>
                    <Paragraph>{ registeredAddress?.adminUnitLevel1 === "FI" ? "FINLAND" : registeredAddress?.adminUnitLevel1 }</Paragraph>
                  </td>
                </tr>
                <tr>
                  <td>TIN</td><td colSpan={4}>{legalEntity?.legalidentifier?.notation}</td>
                </tr>
                <tr>
                  <td>EUID</td><td></td><td colSpan={2}>where applicable</td>
                  <td>{""}</td>
                </tr>
              </tbody>
            </table>
            <div style={{textAlign: "center"}}>
              <Text variant="bold">CERTIFIED INFORMATION</Text>
            </div>

            <table className='certificate-table' cellSpacing={0}>
              <tbody>
                <tr>
                  <td style={{width: "50%"}}>The tax Authorities of</td><td>{country}</td>
                </tr>
                <tr>
                  <td>Certifies to the best of their knowledge based on the available information, that the above mentioned taxpayer is resident of</td><td>{country}</td>
                </tr>
                <tr>
                  <td>Covered period</td><td>{coveredPeriodStart.toLocaleDateString(/*selectedLanguage*/)} – {coveredPeriodEnd.toLocaleDateString(/*selectedLanguage*/)}</td>
                </tr>
                <tr>
                  <td>Date of issuance</td><td>{dateOfIssuance.toLocaleDateString()}</td>
                </tr>
                <tr>
                  <td>Relevant Double Tax Treaty</td><td>{ vc?.credentialSubject?.relevantDoubleTaxTreaty || ""}</td>
                </tr>
                <tr>
                  <td>Additional information [necessary for proving the taxpayer's residence for tax purposes insofar as the certificate is not to be used for relief or withholding tax within the EU based on the available information]</td>
                  <td>{ vc?.credentialSubject?.additionalInformation || ""}</td>
                </tr>
              </tbody>
            </table>
            <div style={{textAlign: "center"}}>
              <Text variant="bold">SHORT NOTICE ABOUT THE VERIFICATION METHOD</Text>
            </div>
            <table className='certificate-table' cellSpacing={0}>
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
            <br/><br/>
            <table cellSpacing={0}>
              <tbody>
                <tr>
                  <td style={{verticalAlign: "center"}}>Date of issuance</td><td style={{width: "40px"}}></td>
                  <td style={{verticalAlign: "center"}}>E-signature</td>
                </tr>
                <tr>
                  <td>
                    <div style={{borderBottom: "1px solid black"}}>{ new Date(vc.issuanceDate||new Date()).toLocaleDateString(/*selectedLanguage*/)}</div>
                  </td>
                  <td></td>
                  <td><div style={{borderBottom: "1px solid black"}}><a href={legalidentifier?.issuingAuthorityURI||'#'} rel="noreferrer" target="_blank" title={legalidentifier?.issuingAuthorityName}>{vc.issuer}</a></div></td>
                </tr>
              </tbody>
            </table>

        {/* <Block variant='section'>
          <Heading variant="h2">{t("credentialView.certificateTitle")} </Heading>
          
          <Text variant="bold">{t("credentialView.issuer")}</Text>
          <Paragraph><a href={legalidentifier?.issuingAuthorityURI||'#'} rel="noreferrer" target="_blank">{legalidentifier?.issuingAuthorityName}</a></Paragraph>
          <Paragraph>{vc.issuer}</Paragraph>
          
         
          <Text variant="bold">{t("credentialView.issuanceDate")} </Text>
          <Paragraph>{new Date(legalidentifier?.dateOfIssue || vc.issuanceDate||new Date()).toLocaleDateString(selectedLanguage)}</Paragraph>
        
          <Text variant="bold">{t("credentialView.notation")} </Text>
          <Paragraph>{legalidentifier?.notation}</Paragraph>

          <Text variant="bold">{t("credentialView.legalName")} </Text>
          <Paragraph>{ legalEntity?.legalName}</Paragraph>

          <Text variant="bold">{t("credentialView.registeredAddress")} </Text>
          { registeredAddress &&
          <>
            
    
            <Paragraph>{ registeredAddress?.fullAddress }</Paragraph>
            <Paragraph>{ registeredAddress?.addressArea }</Paragraph>
            <Paragraph>{ registeredAddress?.postName }</Paragraph>
            
            <Paragraph>{ registeredAddress?.poBox }</Paragraph>
            <Paragraph>{ registeredAddress?.postName } { registeredAddress?.postCode }</Paragraph>
            <Paragraph>{ registeredAddress?.adminUnitLevel1 === "FI" ? "FINLAND" : registeredAddress?.adminUnitLevel1 }</Paragraph>
            
          </>
          }
          
        </Block> */}

        </Paper>  

      }
    </div>
  )
}

export default CredentialView;