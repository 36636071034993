
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Form, useActionData, useLocation, useNavigation } from 'react-router-dom';
import { Button, Checkbox, Heading, LoadingSpinner, Paragraph, TextInput } from 'suomifi-ui-components'

import { Box,  Paper } from '@mui/material';
import { VcObject, WalletsAPI } from '../api/wallets-api/wallets-api';
import EuccView from './EuccView';
import { Eucc } from '../models/eucc';
import Agent3View from './Agent3View';
import { Agent3 } from '../models/agent3';
import EtrcView from './EtrcView';
import { Etrc } from '../models/etrc';
import QRCode from 'react-qr-code';

const PANKKI_API_URL = process.env.REACT_APP_PANKKI_API_URL || process.env.PANKKI_API_URL || "/api";
const PANKKI_API_KEY = process.env.REACT_APP_PANKKI_API_KEY || process.env.PANKKI_API_KEY || "";

type Props = {}

interface IActionData {
  message: string|undefined,
  success: boolean|undefined,
  error: string|undefined,
  requestId: string|undefined
  openid4vp: string|undefined
}

const KycViewWaltId = (props: Props) => {
  const { t, i18n } = useTranslation();

  const data = useActionData() as IActionData;
  const navigation = useNavigation();

  const loading = navigation.state === "loading";
  const submitting = navigation.state === "submitting";
  const [ finalResult, setFinalResult ] = useState<string|null>(null);
  const [ pollCounter, setPollCounter ] = useState<number>(0);
  const [ polling, setPolling ] = useState<boolean>(false);
  const [ vcs, setVcs ] = useState<VcObject[]>([]);

  const [ openId4Vp, setOpenId4Vp] = useState<string|undefined>(undefined);
  if (data?.openid4vp) {
    if (data.openid4vp !== openId4Vp)
      setOpenId4Vp(data.openid4vp);
  }
  

  useEffect(() => { // [data, pollCounter, finalResult]);
    if (data?.requestId) {
      console.log("polling...");
      if (!polling && !finalResult) {
        setPolling(true);
      }
   
      const walletsApi = new WalletsAPI({ baseUrl: PANKKI_API_URL, baseApiParams: { headers: { "x-functions-key": PANKKI_API_KEY }}}); // REACT_APP_PANKKI_API_KEY added to devops build
      console.log("pollPresentationRequest");

      if (finalResult) {
        debugger;
        setPolling(false);
        setOpenId4Vp(undefined)

        walletsApi.pankki.getVerifiedCertificatesByState({ requestId: finalResult }).then(x => {
          if (x.data?.length > 0) {
            let latests: VcObject[] = []

            // Get latest values for each credential type
            const vcTypes = ["eucc", "etrc", "agent3"];
            for(let vcType of vcTypes) {
              // TODO type vs types?
              const latest = x.data.filter(x => x.type && x.type.indexOf(vcType) >= 0).sort((a,b) => {
                const dataA = new Date(a.issuanceDate||new Date());
                const dataB = new Date(b.issuanceDate||new Date());
                return dataA > dataB ? -1 : 1;
              })[0];
              if (latest) {
                latests.push(latest);
              }
            }

            setVcs(latests);
          }
          
        }).catch(x => {
          console.log(x);
        });

      }
      else {
        setTimeout(() => {
          walletsApi.pankki.pollPresentationRequest({ requestId: data.requestId! }).then(result => {
            try {
              if (result && result.ok) {
                result.text().then(text => {
                  //return redirect("/user/success")

                  if (text === "True") {
                    setFinalResult(data.requestId||"");

                  }
                  else {
                    setPollCounter(pollCounter+1); 
                  }
                }); 
              }
              else {
                setPollCounter(pollCounter+1); 
              }         
            } catch {
              alert(result);
            }
          }).catch((err) => {
            console.log(err);
          });
        }, 3000);

      }
      
      
    }
    
  }, [data, pollCounter, finalResult, polling]);

  function renderSwitch(vc: VcObject) {
    const type = vc.type![vc.type!.length-1];

    switch(type) {
      case "eucc":
        return <EuccView issuerDid={vc.issuer||"PRH"} vc={vc.credentialSubject as Eucc}></EuccView>
      case "agent3":
        return <Agent3View issuerDid={vc.issuer||"PRH"} vc={vc.credentialSubject as Agent3}></Agent3View>
      case "etrc":
        return <EtrcView issuerDid={vc.issuer||"PRH"} vc={vc as Etrc}></EtrcView>
      // case "xbrlje":
      //   return <XbrljeView issuerDid={vc.issuer||"PRH"} vc={vc.credentialSubject as Xbrlje}></XbrljeView>
      default:
        return <pre>{JSON.stringify(vc.credentialSubject, null, 2)}</pre>
    }
  }
  return (

    <Form method='post'>
      <Heading variant='h2'>{t('kycViewOld.title')}</Heading>
      <Paragraph>{t('kycViewOld.description')}</Paragraph>
      <Checkbox checked={true} value='eucc'>{t("credentialNames.eucc")}</Checkbox>
      <Checkbox checked={true} value='etrc'>{t("credentialNames.etrc")}</Checkbox>
      <Checkbox checked={true} value='agent3'>{t("credentialNames.agent3")}</Checkbox>  

      <br />
      <Paragraph>{t('kycViewOld.info1')}</Paragraph>
      <Paragraph>{t('kycViewOld.info2')}</Paragraph>

      {/* <TextInput readOnly={false} className='longer-input' name='walletAddress' labelText={t("eudtrcView.walletAddress")} defaultValue="did:web:yrityslompakko.minisuomi.net:7011367-6"></TextInput> */}
    
      {/* <Button disabled={loading} type='submit'>{t('kycViewOld.fetchCredentials')}</Button> */}
      <Button disabled={loading} type='submit'>{t('kycViewOld.showQrCodes')}</Button>

      { openId4Vp && !finalResult &&
      <div style={{ height: "auto", margin: "0 auto", maxWidth: 256, width: "100%" }}>
        <a href={openId4Vp}>
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={ openId4Vp }
            viewBox={`0 0 256 256`}
            />
          </a>  
        </div> 
      }

      <div style={{height: "60px"}}>
        <br/>
      { (loading || polling) && 
        <LoadingSpinner key="loadingSpinner" text={ t("kycViewOld.pollCertificate") } textAlign='right'></LoadingSpinner>
      }
      </div>

      {  data && data.error !== undefined && //data?.success === true && finalResult === null &&
        
        <Box mt={1}>
          <span color='red'>{data.error}</span>
          

          
      
        </Box>
      } 
      {/* { finalResult &&
      <FormGroup>
        <FormControlLabel 
          control={<Switch checked={visualize} 
          onChange={() => setVisualize(old => !old)} />} 
          label={t("credentialView.visualize")} 
          labelPlacement="start" 
        />
      </FormGroup>
      } */}
      

      {/* <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        { vcs.map(vc => 
          <>
            { vc.type && vc.type.length > 0 &&
              <Tab id={`simple-tab-${vc.type[vc.type.length-1]}`} aria-controls={`simple-tabpanel-${vc.type[vc.type.length-1]}`} label={ t(`credentialNames.${vc.type[vc.type.length-1]}`) } ></Tab>
            }
          </>
          )
        }
      </Tabs> */}

      { vcs.map(vc => 
      <div key={vc.id}>
        { vc.type && vc.type.length > 0 &&
          <h3>{ t(`credentialNames.${vc.type[vc.type.length-1]}`) }:</h3>
        }
        <Paper key={vc.type![vc.type!.length-1]} id={`credential-tabpanel-${vc.type![vc.type!.length-1]}`} sx={{mb: 2, pt: 4, pb: 8}}> 
          {/* { visualize ? */}{
           
            renderSwitch(vc) 
          }
            {/* <pre>{JSON.stringify(vc.credentialSubject, null, 2)}</pre>
          } */}
        </Paper>
        </div>
      )}

    </Form>
  )
}

export const startKycWaltIdPresentationRequests = async ({ request }: any) => {
  debugger;
  const data = Object.fromEntries(await request.formData());

  const vcTypes = ["eucc", "etrc", "agent3"];

  try {
    const walletsApi = new WalletsAPI({ baseUrl: PANKKI_API_URL, baseApiParams: { headers: { "x-functions-key": PANKKI_API_KEY }}}); // REACT_APP_PANKKI_API_KEY added to devops build
    const response = await walletsApi.pankki.startCredentialsPresentationRequestWithWebDid({ credentialNames: vcTypes.join(","), webDid: data.walletAddress||"did:web:yrityslompakko.minisuomi.net:7011367-6" });
    const openid4vp = await response.text();
    const url = new URL(openid4vp);
    const state = url.searchParams.get("state");

    return { message: "Pyyntö lähetetty", openid4vp: openid4vp||"", requestId: state||"", success: true } as IActionData;  
  }
  catch (err: any) {
    return { error: err?.error?.message || err?.message || "failed", message: "Failed to send request" } as IActionData
  }
}


export default KycViewWaltId