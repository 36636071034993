
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Form } from 'react-router-dom';
import { Button, Checkbox, Heading, Paragraph, TextInput } from 'suomifi-ui-components'

import { Box } from '@mui/material';
import QRCode from 'react-qr-code';
import vceApi from '../vceApi';
import { SimpleClaim, SimplifiedPresentationPayload } from '../api/vce-api/vce-api';
import Eucc, { EuccCredentialSdJwt } from './credentials/Eucc';
import Lpid, { LpidCredentialSdJwt } from './credentials/Lpid';

// const PANKKI_API_URL = process.env.REACT_APP_PANKKI_API_URL || process.env.PANKKI_API_URL || "/api";
// const PANKKI_API_KEY = process.env.REACT_APP_PANKKI_API_KEY || process.env.PANKKI_API_KEY || "";

const verifierName = "verifier"; // should it use test/local?

type Props = {}


const KycView = (props: Props) => {
  const { t, i18n } = useTranslation();

  const [ pollCounter, setPollCounter ] = useState<number>(0);
  const [ walletAddress, setWalletAddress ] = useState<string>("");
  const [ state, setState ] = useState<string>(""); 
  const [ openId4Vp, setOpenId4Vp] = useState<string|undefined>(undefined);
  const [ errorText, setErrorText ] = useState<string>(""); 
  
  const [ lpid, setLPID ] = useState<LpidCredentialSdJwt|undefined>(); 
  const [ eucc, setEUCC ]= useState<EuccCredentialSdJwt|undefined>(); 

  const [ requestEucc, setRequestEucc ] = useState<boolean>(true);
  const [ requestLpid, setRequestLpid ] = useState<boolean>(true);

  const loading = false;

  const onLpidCheckboxChange = (checked: boolean) => {
    setPollCounter(0);
    setState("");
    setOpenId4Vp(undefined);
    setRequestLpid(checked);
  }

  const onEuccCheckboxChange = (checked: boolean) => {
    setPollCounter(0);
    setState("");
    setOpenId4Vp(undefined)
    setRequestEucc(checked);
  }

  const onSubmitClick = () => {
    //setIsLoading(true);
    setPollCounter(0);
    setState("");
    setOpenId4Vp(undefined)
    onSubmitClickAsync().then((result) => {
      debugger;
      if (result !== null) {
        setOpenId4Vp(result.openid4vp);
        setState(result.state);
      }
    })
  }

  const onSubmitClickAsync = async (): Promise<{ openid4vp?: string, state: string }|null> => {
    const euccClaims = ["legalName", "legalFormType", "legalIdentifier", "registeredAddress", "postalAddress", "registrationDate", "shareCapital", "legalRepresentative", "legalEntityActivity", "contactPoint", "issuer_name", "issuer_id", "issuer_country", "issuance_date", "expire_date", "authentic_source_id", "authentic_source_name"];
    const lpidClaims = ["legal_person_id", "legal_person_name", "issuer_name", "issuer_id", "issuer_country", "issuance_date", "expire_date", "authentic_source_id", "authentic_source_name", "credential_status"/*, "credential_schema"*/ ];

    const newState = window.crypto.randomUUID().replace(/-/g,"");
       
    // spherity supports only single element in path, so use sd-jwt.
    const response = await vceApi.verifiers.createPresentationRequest(verifierName, { /*clientId: clientId,*/ state: newState, pathType: "sd-jwt" }, [
      ...(requestLpid ? [
      {
        credentialType: "LPID",
        claims: lpidClaims.map(x => { return { name: x } as SimpleClaim})
      }] : [])
      ,
      ...(requestEucc ? [
        {
          credentialType: "EUCC",
          claims: euccClaims.map(x => { return { name: x } as SimpleClaim})
        }
      ] : [])
    ]);

    
    const initiateResponse = await vceApi.verifiers.initiateTransaction("verifier", response.data);
    const _openid4vp = initiateResponse.data.responseUri;

    const walletAddressTrimmed = walletAddress.trim();
    if (walletAddressTrimmed.length > 0) {
      let holderUrl = walletAddress
      if (walletAddressTrimmed.startsWith("did:web:")) { 
        holderUrl = "https://" + decodeURIComponent(walletAddressTrimmed.substring(8)).replaceAll(":", "/")
      }
      else {
        holderUrl = walletAddressTrimmed;
      }
      try {
        const getResult = await fetch(holderUrl + "?" + _openid4vp.replace("openid4vp://?", ""));
        console.log("get result", JSON.stringify(getResult));
        //   const postData = openid4vp;
        // console.log("POST", holderUrl, postData);
        // const postResult = await fetch(holderUrl, {
        //   method: "POST",
        //   body: postData // todo?
        //   // headers:{
        //   //   'Content-Type': 'application/x-www-form-urlencoded'
        //   // },    
        //   // body: new URLSearchParams({'foo': 'bar',... })
        // })
        // console.log("post result", JSON.stringify(postResult));
        
        //return null; // polling needs state...
        return { state: newState };
      }
      catch(err: any) {
        console.log(err);
        setErrorText(JSON.stringify(err))
      }
    }

    return { openid4vp: _openid4vp, state: newState };
  };


  useEffect(() => {
    if (state !== "") {
      // poll method tries to poll one minute
      vceApi.verifiers.poll(verifierName, state, { credentials: 'omit' }).then(async result => {
        debugger;
        if (result.error) {
          setErrorText(JSON.stringify(result.error))
        }
        if (result === null || result.data === null) {
          // no result yet, try to poll up to 5 minutes
          if (pollCounter === 5) {
            setOpenId4Vp(undefined);
            setPollCounter(0);
          }
          else {
            setPollCounter(pollCounter+1);
          }          
        }
        else {
          const data = result.data as SimplifiedPresentationPayload 
          console.log(data);
          const lpid = data?.credentials.find(x => x.vct === "LPID");
          const eucc = data?.credentials.find(x => x.vct === "EUCC");
          
          setLPID(lpid as LpidCredentialSdJwt);
          setEUCC(eucc as EuccCredentialSdJwt);
        }    
      })
    }
  }, [state, pollCounter])

  return (

    <Form method='post'>
      {/* <input type='hidden' value={state} ></input> */}
      <Heading variant='h2'>{t('kycView.title')}</Heading>
      <Paragraph>{t('kycView.description')}</Paragraph>
      <Checkbox name='requestLpid' checked={requestLpid} onClick={(newState) => { onLpidCheckboxChange(newState.checkboxState); } }>{t("credentialNames.lpid")}</Checkbox>
      <Checkbox name='requestEucc' checked={requestEucc} onClick={(newState) => { onEuccCheckboxChange(newState.checkboxState); } }>{t("credentialNames.eucc2")}</Checkbox>


      <br />
      <Paragraph>{t('kycView.info1')}</Paragraph>
      <Paragraph>{t('kycView.info2')}</Paragraph>

      {/* (for ex. 
      <a href="https://tech-did-web.dev.ssi.bosch.tech/did/cfd78fe3-febd-4adc-b883-0b4d189d85bb/did.json">did:web:tech-did-web.dev.ssi.bosch.tech:did:cfd78fe3-febd-4adc-b883-0b4d189d85bb</a> 
      <br/>or<br/> 
      <a href="did:web:test.minisuomi.fi:holders:y-tunnus/did.json">did:web:test.minisuomi.fi:holders:y-tunnus (not exists yet)</a> 
      )   */}

      <TextInput readOnly={false} className='longer-input' name='walletAddress' labelText={t("eudtrcView.walletAddress")} value={walletAddress} onChange={(e) => setWalletAddress(e as string)}></TextInput>
        
      { walletAddress.length !== 0 ?
        <Button disabled={loading || (!requestEucc && !requestLpid) || lpid !== undefined || eucc !== undefined} type='button' onClick={onSubmitClick}>{t('kycView.fetchCredentials')}</Button>
        :
        <Button disabled={loading || (!requestEucc && !requestLpid) || lpid !== undefined || eucc !== undefined } type='button' onClick={onSubmitClick}>{t('kycView.showQrCodes')}</Button> 
      }

      <br />
      <br />

      <div style={{ height: "auto", margin: "0 auto", maxWidth: 256, width: "100%" }}>
        { openId4Vp && state && !lpid && !eucc && (requestEucc || requestLpid) &&
        <>
        <a href={openId4Vp}>
          <QRCode key={state}
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={ openId4Vp }
            viewBox={`0 0 256 256`}
            />
          </a>
          <b>
            { requestEucc && requestLpid ? "LPID and EUCC" : (requestEucc ? "EUCC" : requestLpid ? "LPID" : "no") } request
          </b>
          
          <br/>
          valid for { 5 - Math.floor(pollCounter / 2) } minutes
          </>
        }
      </div> 
    
      { errorText && 
        <Box mt={1}>
          <span color='red'>{errorText}</span>  
      
        </Box>
      }

      { lpid && 
        <Lpid credential={lpid}></Lpid>
      }
      <br/><br/>
      { eucc &&
        <Eucc credential={eucc}></Eucc>
      }

    </Form>
  )
}

export default KycView