import React, { useState } from 'react'
import { Heading } from 'suomifi-ui-components';
import { FormControlLabel, FormGroup, Paper, Switch } from '@mui/material';
import { t } from 'i18next';
import { JWK } from '../../api/vce-api/vce-api';


export interface LpidCredentialSdJwt {
  iss: string
  iat: number
  exp: number
  cvt: string
  cnf?: JWK

  legal_person_id: string // this is must have to field, no disclosure?
  
  legal_person_name: string
  issuer_name: string
  issuer_id: string
  issuer_country: string
  issuance_date: string
  expire_date: string
  authentic_source_id: string
  authentic_source_name: string
  credential_status: string
  credential_schema: string
}



type Props = { 
  credential: LpidCredentialSdJwt // | LpidCredentialJwt
}

const Lpid = (props: Props) => {
  const [visualize, setVisualize] = useState<boolean>(true);
  const vc = props.credential;

  return (
    <Paper style={{paddingTop: "30px", paddingBottom: "50px" }}>

      <FormGroup>
        <FormControlLabel 
          control={<Switch checked={visualize} 
          onChange={() => setVisualize(old => !old)} />} 
          label={t("credentialView.visualize")} 
          labelPlacement="start" 
        />
      </FormGroup>

      { !visualize ? <pre>{JSON.stringify(vc, null, 2)}</pre> :
      <div>
        <p>
          Issuer: { vc.iss }<br/>
          {/* Issuer Business ID: { vc.legalIdentifier.notation }<br/> */}
          Date and time of creating the document: { vc.issuance_date }<br/>
        </p>
        <Heading variant='h3'>LEGAL ENTITY PERSON IDENTIFICATION DATA (LPID)</Heading>
        <br/>
        
        
        <table cellSpacing={0} style={{textAlign: "left"}}>
          <tbody>
          <tr>
            <th colSpan={3}>
              <Heading variant='h4' style={{borderBottom: "1px solid black", display: "inline"}}>Identifiers</Heading>
            </th>
          </tr>
          <tr>
            <td title="Name of the company">Company name</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.legal_person_name}</td>
          </tr>
          {/* <tr>
            <td title="Finnish Business Identity Code">Business ID</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.notation}</td>
          </tr> */}
          <tr>
            <td title="EU Business Identity Code">EUID</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.legal_person_id}</td>
          </tr>
          {/* <tr>
            <td title="Date on which the PRH has made the register entry">Registration date</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.registrationDate}</td>
          </tr> */}
          {/* <tr>
            <td>Company type</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.legalFormType }</td>
          </tr> */}
          {/* <tr>
            <td title="Postal address">Contact details:</td>
            <td style={{minWidth: "20px"}}></td>
            <td></td>
          </tr> */}
          {/* {vc.postalAddress.poBox && 
            <tr>
              <td title="Postal address">Postall address</td>
              <td style={{minWidth: "20px"}}></td>
              <td>PL {vc.postalAddress.poBox} {vc.postalAddress.postName}</td>
            </tr>
          } */}
          {/* { vc.postalAddress?.length &&
          <tr>
            <td title="Street address">Street address</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.postalAddress[0].post_code + " " + vc.postalAddress[0].post_name}</td>
          </tr>
          } */}

          <tr><td><br/></td></tr>
          {/* <tr>
            <th>
              <Heading variant='h4' style={{borderBottom: "1px solid black", display: "inline"}} title="Domicile (municipality where the company is managed, place of registered office)">Registered office</Heading>
            </th>
            <td></td>
            <td>{vc.registeredOffice.postName} {vc.registeredOffice.adminUnitL1}</td>
          </tr> */}
        

          </tbody>
        </table>

        {/* <Heading variant='h4' style={{borderBottom: "1px solid black", display: "inline"}}>Financial period</Heading>
        <table>
          <tr>
            <td>Financial period</td>
            <td style={{minWidth: "20px"}}></td>
            <td>{vc.durationOfTheLegalEntity}</td>  01.01 - 31.12
          </tr>
        </table> */}

        <br/>
        
        <br/>
        <table cellSpacing={0} style={{textAlign:"left"}}>
          <tbody>
          <tr>
            <th>
              <Heading variant='h4' style={{borderBottom: "1px solid black", display: "inline"}}>Name history</Heading>
            </th>
          </tr>
          <tr>
            <td>{vc.legal_person_name} {/*vc.registrationDate*/} - present</td>
          </tr>
          <tr>
            <td><p>Source of information: { vc.authentic_source_name }</p></td>
          </tr>
          </tbody>
        </table>

        <br/>
        <table cellSpacing={0}>
          <tbody>
            <tr>
              <td style={{verticalAlign: "center"}}>Date of issuance</td><td style={{width: "40px"}}></td>
              <td style={{verticalAlign: "center"}}>E-signature</td>
            </tr>
            <tr>
              <td>
                <div style={{borderBottom: "1px solid black"}}>{ /*vc.registrationDate ||*/new Date().toLocaleDateString(/*selectedLanguage*/)}</div>
              </td>
              <td></td>
              <td>
                {/* <div style={{borderBottom: "1px solid black"}}><a href="https://pankki.minisuomi.net" rel="noreferrer" target="_blank" title={vc.legalName}></a></div> */}
              </td>
            </tr>
          </tbody>
        </table>
      </div> 
      }
    </Paper>
  )
}

export default Lpid