import React from 'react';
import './index.css';
import App from './App';
import "@fontsource/source-sans-pro";
import './i18n';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container); 
root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>
  );
