import { Dispatch, SetStateAction, createContext, useContext } from "react";

export interface IUserData {
  isLoggedIn: boolean;
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>;
  name: string|null;
  setName: Dispatch<SetStateAction<string|null>>;
  company: string|null;
  setCompany: Dispatch<SetStateAction<string|null>>;
  companyId: string|null;
  setCompanyId: Dispatch<SetStateAction<string|null>>;
  // role: string|null;
  // setRole: Dispatch<SetStateAction<string|null>>;
  // authorizationRoles: SuomiFiAuthorizationRole[];
  // setAuthorizationRoles: Dispatch<SetStateAction<SuomiFiAuthorizationRole[]>>;
}
export const UserContext = createContext<IUserData>({ 
  isLoggedIn: false, setIsLoggedIn: () => { },
  name: "", setName: () => { }, 
  company: "", setCompany: () => {},
  companyId: "", setCompanyId: () => {},
  // role: "", setRole: () => {},
  // authorizationRoles: [], setAuthorizationRoles: () => {}
});
export const useUserContext = () => useContext(UserContext);