import React, { useEffect } from 'react'
import { Block, Heading,  Paragraph } from 'suomifi-ui-components';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { useUserContext } from '../../context/UserContext';

type Props = {}

const LogoutView = (props: Props) => {
  const userContext = useUserContext();

  useEffect(() => {
    sessionStorage.clear();
    localStorage.clear();

    userContext.setIsLoggedIn(false);
    userContext.setName(null);  
  }, [userContext]);

  return (
    <Block>
      <Heading variant="h1">{t('logoutView.title')}</Heading>  
      <Paragraph>
        <Link to='/'>{t('logoutView.redirect')}</Link>
      </Paragraph>
    </Block> 
  )
}

export default LogoutView