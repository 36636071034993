/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AttachDecorator {
  "@id"?: string;
  /** @format int32 */
  byte_count?: number;
  data: AttachDecoratorData;
  description?: string;
  filename?: string;
  /** @pattern /^\\d{4}-\\d\\d-\\d\\d[T ]\\d\\d:\\d\\d(?:\\:(?:\\d\\d(?:\\.\\d{1,6})?))?(?:[+-]\\d\\d:?\\d\\d|Z|)$/ */
  lastmod_time?: string;
  "mime-type"?: string;
}

export interface AttachDecoratorData {
  /** @pattern /^[a-zA-Z0-9+\/]*={0,2}$/ */
  base64?: string;
  json?: object;
  jws?: AttachDecoratorDataJWS;
  links?: string[];
  /** @pattern /^[a-fA-F0-9+\/]{64}$/ */
  sha256?: string;
}

export interface AttachDecoratorData1JWS {
  header: AttachDecoratorDataJWSHeader;
  /** @pattern /^[-_a-zA-Z0-9]*$/ */
  protected?: string;
  /**
   * @minLength 1
   * @pattern /^[-_a-zA-Z0-9]*$/
   */
  signature: string;
}

export interface AttachDecoratorDataJWS {
  header?: AttachDecoratorDataJWSHeader;
  /** @pattern /^[-_a-zA-Z0-9]*$/ */
  protected?: string;
  /** @pattern /^[-_a-zA-Z0-9]*$/ */
  signature?: string;
  signatures?: AttachDecoratorData1JWS[];
}

export interface AttachDecoratorDataJWSHeader {
  /**
   * @minLength 1
   * @pattern /^did:(?:key:z[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]+|sov:[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}(;.*)?(\\?.*)?#.+)$/
   */
  kid: string;
}

export interface AvailableCredential {
  cred_def_id?: string;
  title_fi?: string;
  title_en?: string;
  description_fi?: string;
  description_en?: string;
}

export interface AvailableRecord {
  record_id?: string;
  label?: string;
  description?: string;
}

export interface BasicMessage {
  connection_id?: string;
  message_id?: string;
  content?: string;
  state?: string;
  sent_time?: string;
}

export interface BasicMessageWrapper {
  message?: BasicMessage;
  isRead?: boolean;
}

export interface ConnectionInvitation {
  "@id"?: string;
  "@type"?: string;
  /** @pattern /^(did:sov:)?[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}$/ */
  did?: string;
  imageUrl?: string;
  label?: string;
  recipientKeys?: string[];
  routingKeys?: string[];
  serviceEndpoint?: string;
}

export interface ConnectionList {
  results?: ConnRecord[];
}

export interface ConnRecord {
  /** @default "manual" */
  accept?: "manual" | "auto";
  alias?: string;
  connection_id?: string;
  /** @default "connections/1.0" */
  connection_protocol?: "connections/1.0" | "didexchange/1.0";
  /** @pattern /^\\d{4}-\\d\\d-\\d\\d[T ]\\d\\d:\\d\\d(?:\\:(?:\\d\\d(?:\\.\\d{1,6})?))?(?:[+-]\\d\\d:?\\d\\d|Z|)$/ */
  created_at?: string;
  error_msg?: string;
  inbound_connection_id?: string;
  /** @pattern /^[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{43,44}$/ */
  invitation_key?: string;
  /** @default "once" */
  invitation_mode?: "once" | "multi" | "static";
  invitation_msg_id?: string;
  /** @pattern /^(did:sov:)?[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}$/ */
  my_did?: string;
  request_id?: string;
  rfc23_state?: string;
  /** @default "none" */
  routing_state?: "none" | "request" | "active" | "error";
  state?: string;
  /** @pattern /^(did:sov:)?[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}$/ */
  their_did?: string;
  their_label?: string;
  their_public_did?: string;
  /** @default "invitee" */
  their_role?: "invitee" | "requester" | "inviter" | "responder";
  /** @pattern /^\\d{4}-\\d\\d-\\d\\d[T ]\\d\\d:\\d\\d(?:\\:(?:\\d\\d(?:\\.\\d{1,6})?))?(?:[+-]\\d\\d:?\\d\\d|Z|)$/ */
  updated_at?: string;
}

export interface CoveredPeriod {
  /** @format date-time */
  startTime?: string;
  /** @format date-time */
  endTime?: string;
}

export interface CredAttrSpec {
  "mime-type"?: string;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  value: string;
}

export interface CredentialDefinition {
  /** @pattern /^([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}):3:CL:(([1-9][0-9]*)|([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}:2:.+:[0-9.]+)):(.+)?$/ */
  id?: string;
  schemaId?: string;
  tag?: string;
  type?: object;
  value?: CredentialDefinitionValue;
  /** @pattern /^[0-9.]+$/ */
  ver?: string;
}

export interface CredentialDefinitionGetResult {
  credential_definition?: CredentialDefinition;
}

export type CredentialDefinitionValue = object;

export interface CredentialExchangeResponses {
  v10results?: V10CredentialExchange[];
  v20results?: V20CredExRecordDetail[];
}

export interface CredentialPreview {
  "@type"?: string;
  attributes: CredAttrSpec[];
}

export interface CredentialResponse {
  credential?: IndyCredInfo;
  w3cCredential?: VcRecord;
  exchange?: V10CredentialExchange;
  v20Exchange?: V20CredExRecordDetail;
}

export interface CredentialSchema {
  id?: string;
  type?: string;
}

export interface CredentialsIssuanceResponse {
  type?: string;
  offer?: string;
}

export interface CredInfoList {
  results?: IndyCredInfo[];
}

export type DictionaryString = Record<string, string>;

export interface Did {
  /** @pattern /^did:key:z[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]+$|^(did:sov:)?[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}$/ */
  did?: string;
  /** @default "ed25519" */
  key_type?: "ed25519" | "bls12381g2";
  /** @default "sov" */
  method?: "sov" | "key";
  /** @default "public" */
  posture?: "public" | "posted" | "wallet_only";
  /** @pattern /^[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{43,44}$/ */
  verkey?: string;
}

export interface DidList {
  results?: Did[];
}

export interface Domicile {
  addressArea?: string;
  addressId?: string;
  adminUnitLevel1?: string;
  adminUnitLevel2?: string;
  fullAddress?: string;
  locatorDesignator?: string;
  locatorName?: string;
  poBox?: string;
  postCode?: string;
  postName?: string;
  thoroughfare?: string;
}

export interface EReceiptIssuanceResponse {
  issuanceRequest?: string;
  credentialId?: string;
}

export interface EudtrcIssuanceResponse {
  companyCode?: string;
}

export interface IdentifierAs {
  /** @format date-time */
  dateOfIssue?: string;
  issuingAuthorityName?: string;
  issuingAuthorityURI?: string;
  notation?: string;
  schemeName?: string;
  schemeURI?: string;
}

export type IndyCredAbstractKeyCorrectnessProof = object;

export interface IndyCredInfo {
  attrs?: Record<string, string>;
  /** @pattern /^([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}):3:CL:(([1-9][0-9]*)|([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}:2:.+:[0-9.]+)):(.+)?$/ */
  cred_def_id?: string;
  /** @pattern /^[1-9][0-9]*$/ */
  cred_rev_id?: string;
  referent?: string;
  /** @pattern /^([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}):4:([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}):3:CL:(([1-9][0-9]*)|([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}:2:.+:[0-9.]+))(:.+)?:CL_ACCUM:(.+$)/ */
  rev_reg_id?: string;
  /** @pattern /^[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}:2:.+:[0-9.]+$/ */
  schema_id?: string;
}

export interface IndyCredPrecis {
  cred_info?: IndyCredPrecisCredInfo;
  interval?: IndyCredPrecisInterval;
  presentation_referents?: string[];
}

export interface IndyCredPrecisCredInfo {
  attrs?: Record<string, string>;
  /** @pattern /^([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}):3:CL:(([1-9][0-9]*)|([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}:2:.+:[0-9.]+)):(.+)?$/ */
  cred_def_id?: string;
  /** @pattern /^[1-9][0-9]*$/ */
  cred_rev_id?: string;
  referent?: string;
  /** @pattern /^([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}):4:([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}):3:CL:(([1-9][0-9]*)|([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}:2:.+:[0-9.]+))(:.+)?:CL_ACCUM:(.+$)/ */
  rev_reg_id?: string;
  /** @pattern /^[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}:2:.+:[0-9.]+$/ */
  schema_id?: string;
}

export interface IndyCredPrecisInterval {
  /**
   * @format int32
   * @min 0
   * @max 384
   */
  from?: number;
  /**
   * @format int32
   * @min 0
   * @max 384
   */
  to?: number;
}

export interface IndyGEProof {
  /** @pattern /^[0-9]*$/ */
  alpha?: string;
  /** @pattern /^[0-9]*$/ */
  mj?: string;
  predicate?: IndyGEProofPred;
  r?: Record<string, string>;
  t?: Record<string, string>;
  u?: Record<string, string>;
}

export interface IndyGEProofPred {
  attr_name?: string;
  /** @default "LT" */
  p_type?: "LT" | "LE" | "GE" | "GT";
  /** @format int32 */
  value?: number;
}

export interface IndyPresAttrSpec {
  /** @pattern /^([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}):3:CL:(([1-9][0-9]*)|([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}:2:.+:[0-9.]+)):(.+)?$/ */
  cred_def_id?: string;
  "mime-type"?: string;
  /** @minLength 1 */
  name: string;
  referent?: string;
  value?: string;
}

export interface IndyPresPredSpec {
  /** @pattern /^([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}):3:CL:(([1-9][0-9]*)|([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}:2:.+:[0-9.]+)):(.+)?$/ */
  cred_def_id?: string;
  /** @minLength 1 */
  name: string;
  /**
   * @minLength 1
   * @default "<"
   */
  predicate: "<" | "<=" | ">=" | ">";
  /** @format int32 */
  threshold: number;
}

export interface IndyPresPreview {
  "@type"?: string;
  attributes: IndyPresAttrSpec[];
  predicates: IndyPresPredSpec[];
}

export interface IndyPresSpec {
  requested_attributes: Record<string, IndyRequestedCredsRequestedAttr>;
  requested_predicates: Record<string, IndyRequestedCredsRequestedPred>;
  self_attested_attributes: Record<string, string>;
  trace?: boolean;
}

export interface IndyPrimaryProofEqProof {
  /** @pattern /^[0-9]*$/ */
  a_prime?: string;
  /** @pattern /^[0-9]*$/ */
  e?: string;
  m?: Record<string, string>;
  /** @pattern /^[0-9]*$/ */
  m2?: string;
  revealed_attrs?: Record<string, string>;
  /** @pattern /^[0-9]*$/ */
  v?: string;
}

export interface IndyProofIdentifier {
  /** @pattern /^([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}):3:CL:(([1-9][0-9]*)|([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}:2:.+:[0-9.]+)):(.+)?$/ */
  cred_def_id?: string;
  /** @pattern /^([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}):4:([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}):3:CL:(([1-9][0-9]*)|([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}:2:.+:[0-9.]+))(:.+)?:CL_ACCUM:(.+$)/ */
  rev_reg_id?: string;
  /** @pattern /^[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}:2:.+:[0-9.]+$/ */
  schema_id?: string;
  /**
   * @format int32
   * @min 0
   * @max 384
   */
  timestamp?: number;
}

export interface IndyProofProof {
  aggregated_proof?: IndyProofProofAggregatedProof;
  proofs?: IndyProofProofProofsProof[];
}

export interface IndyProofProofAggregatedProof {
  c_hash?: string;
  c_list?: ListInt32[];
}

export interface IndyProofProofProofsProof {
  non_revoc_proof?: IndyProofProofProofsProofNonRevocProof;
  primary_proof?: IndyProofProofProofsProofPrimaryProof;
}

export interface IndyProofProofProofsProofNonRevocProof {
  c_list?: Record<string, string>;
  x_list?: Record<string, string>;
}

export interface IndyProofProofProofsProofPrimaryProof {
  eq_proof?: IndyPrimaryProofEqProof;
  ge_proofs?: IndyGEProof[];
}

export interface IndyProofReqAttrSpec {
  name?: string;
  names?: string[];
  non_revoked?: IndyProofReqAttrSpecNonRevoked;
  restrictions?: DictionaryString[];
}

export interface IndyProofReqAttrSpecNonRevoked {
  /**
   * @format int32
   * @min 0
   * @max 384
   */
  from?: number;
  /**
   * @format int32
   * @min 0
   * @max 384
   */
  to?: number;
}

export interface IndyProofReqPredSpec {
  /** @minLength 1 */
  name: string;
  non_revoked?: IndyProofReqAttrSpecNonRevoked;
  /**
   * @minLength 1
   * @default "<"
   */
  p_type: "<" | "<=" | ">=" | ">";
  /** @format int32 */
  p_value: number;
  restrictions?: DictionaryString[];
}

export interface IndyProofRequest {
  name?: string;
  non_revoked?: IndyProofReqAttrSpecNonRevoked;
  /** @pattern /^[1-9][0-9]*$/ */
  nonce?: string;
  requested_attributes: Record<string, IndyProofReqAttrSpec>;
  requested_predicates: Record<string, IndyProofReqPredSpec>;
  /** @pattern /^[0-9.]+$/ */
  version?: string;
}

export interface IndyProofRequestedProof {
  predicates?: Record<string, IndyProofRequestedProofPredicate>;
  revealed_attr_groups?: Record<string, IndyProofRequestedProofRevealedAttrGroup>;
  revealed_attrs?: Record<string, IndyProofRequestedProofRevealedAttr>;
  self_attested_attrs?: IndyProofRequestedProofRevealedAttrGroup;
  unrevealed_attrs?: IndyProofRequestedProofRevealedAttrGroup;
}

export interface IndyProofRequestedProofPredicate {
  /** @format int32 */
  sub_proof_index?: number;
}

export interface IndyProofRequestedProofRevealedAttr {
  /** @pattern /^-?[0-9]*$/ */
  encoded?: string;
  raw?: string;
  /** @format int32 */
  sub_proof_index?: number;
}

export interface IndyProofRequestedProofRevealedAttrGroup {
  /** @format int32 */
  sub_proof_index?: number;
  values?: Record<string, RawEncoded>;
}

export interface IndyRequestedCredsRequestedAttr {
  /** @minLength 1 */
  cred_id: string;
  revealed?: boolean;
}

export interface IndyRequestedCredsRequestedPred {
  /** @minLength 1 */
  cred_id: string;
  /**
   * @format int32
   * @min 0
   * @max 384
   */
  timestamp?: number;
}

export interface InvitationRecord {
  /** @pattern /^\\d{4}-\\d\\d-\\d\\d[T ]\\d\\d:\\d\\d(?:\\:(?:\\d\\d(?:\\.\\d{1,6})?))?(?:[+-]\\d\\d:?\\d\\d|Z|)$/ */
  created_at?: string;
  invi_msg_id?: string;
  invitation?: InvitationRecordInvitation;
  invitation_id?: string;
  invitation_url?: string;
  oob_id?: string;
  state?: string;
  trace?: boolean;
  /** @pattern /^\\d{4}-\\d\\d-\\d\\d[T ]\\d\\d:\\d\\d(?:\\:(?:\\d\\d(?:\\.\\d{1,6})?))?(?:[+-]\\d\\d:?\\d\\d|Z|)$/ */
  updated_at?: string;
}

export type InvitationRecordInvitation = object;

export interface InvitationResult {
  connection_id?: string;
  invitation?: ConnectionInvitation;
  invitation_url?: string;
}

export interface LedgerConfig {
  /** @format int32 */
  ID?: number;
  Label?: string;
  LedgerURL?: string;
  GenesisURL?: string;
}

export interface Legalidentifier {
  /** @format date-time */
  dateOfIssue?: string;
  issuingAuthorityName?: string;
  issuingAuthorityURI?: string;
  notation?: string;
  schemeName?: string;
  schemeURI?: string;
}

export type ListInt32 = number[];

export interface PaymentDetails {
  paymentMeansCode?: string;
  nameOnCard?: string;
  cardNumber?: string;
  expiryDate?: string;
  cvv?: string;
}

export interface PingRequestResponse {
  thread_id?: string;
  connection_id?: string;
  comment?: string;
  state?: string;
  responded?: string;
}

export interface PingResponse {
  connection_id?: string;
  response?: PingRequestResponse;
  /** @format date-time */
  created_at?: string;
}

export interface PlaceOfBirth {
  geographicidentifier?: string;
  geographicname?: string;
}

export interface PresentationResponses {
  v10results?: V10PresentationExchange[];
  v20results?: V20PresExRecord[];
}

export interface ProductItem {
  sku?: string;
  name?: string;
  grossPrice?: string;
  vatPercentage?: string;
}

export interface Purchase {
  items?: PurchaseLine[];
  shippingAddress?: ShippingAddress;
  paymentDetails?: PaymentDetails;
}

export interface PurchaseLine {
  product?: ProductItem;
  /** @format int32 */
  amount?: number;
}

export interface RawEncoded {
  /** @pattern /^-?[0-9]*$/ */
  encoded?: string;
  raw?: string;
}

export interface RegisteredAddress {
  addressArea?: string;
  addressId?: string;
  adminUnitLevel1?: string;
  adminUnitLevel2?: string;
  fullAddress?: string;
  locatorDesignator?: string;
  locatorName?: string;
  poBox?: string;
  postCode?: string;
  postName?: string;
  thoroughfare?: string;
}

export interface Residency {
  name?: string;
  id?: string;
}

export interface Root {
  theTaxAuthoritiesof?: TheTaxAuthoritiesof;
  taxpayerwhosresidencyiscertified?: Taxpayerwhosresidencyiscertified;
  coveredPeriod?: CoveredPeriod;
  /** @format date-time */
  dateOfIssue?: string;
  relevantDoubleTaxTreaty?: string;
  additionalInformation?: string;
  residency?: Residency;
}

export interface Schema {
  attrNames?: string[];
  /** @pattern /^[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}:2:.+:[0-9.]+$/ */
  id?: string;
  name?: string;
  /** @format int32 */
  seqNo?: number;
  /** @pattern /^[0-9.]+$/ */
  ver?: string;
  /** @pattern /^[0-9.]+$/ */
  version?: string;
}

export interface SchemaGetResult {
  schema?: Schema;
}

export interface ShippingAddress {
  firstName?: string;
  lastName?: string;
  addressLine1?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
}

export type Spatial = object;

export interface TaxpayerEntity {
  legalName?: string[];
  legalidentifier?: Legalidentifier;
  registeredAddress?: RegisteredAddress;
  identifierAs?: IdentifierAs;
}

export interface TaxpayerNaturalPerson {
  /** @format date-time */
  dateOfBirth?: string;
  familyName?: string;
  givenName?: string;
  identifierAs?: IdentifierAs;
  domicile?: Domicile;
  placeOfBirth?: PlaceOfBirth;
}

export interface Taxpayerwhosresidencyiscertified {
  taxpayerNaturalPerson?: TaxpayerNaturalPerson;
  taxpayerEntity?: TaxpayerEntity;
}

export interface TheTaxAuthoritiesof {
  spatial?: Spatial;
}

export interface UserInfo {
  id?: string;
  email?: string;
  password?: string;
  token?: string;
  ethAccount?: string;
  did?: string;
  tezosAccount?: string;
  polkadotAccount?: string;
  polkadotEvmAccount?: string;
  flowAccount?: string;
}

export interface V10CredentialExchange {
  auto_issue?: boolean;
  auto_offer?: boolean;
  auto_remove?: boolean;
  connection_id?: string;
  /** @pattern /^\\d{4}-\\d\\d-\\d\\d[T ]\\d\\d:\\d\\d(?:\\:(?:\\d\\d(?:\\.\\d{1,6})?))?(?:[+-]\\d\\d:?\\d\\d|Z|)$/ */
  created_at?: string;
  credential?: V10CredentialExchangeCredential;
  /** @pattern /^([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}):3:CL:(([1-9][0-9]*)|([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}:2:.+:[0-9.]+)):(.+)?$/ */
  credential_definition_id?: string;
  credential_exchange_id?: string;
  credential_id?: string;
  credential_offer?: V10CredentialExchangeCredentialOffer;
  credential_offer_dict?: V10CredentialExchangeCredentialOfferDict;
  credential_proposal_dict?: V10CredentialExchangeCredentialProposalDict;
  credential_request?: V10CredentialExchangeCredentialRequest;
  credential_request_metadata?: object;
  error_msg?: string;
  /** @default "self" */
  initiator?: "self" | "external";
  parent_thread_id?: string;
  raw_credential?: V10CredentialExchangeRawCredential;
  revoc_reg_id?: string;
  revocation_id?: string;
  /** @default "holder" */
  role?: "holder" | "issuer";
  /** @pattern /^[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}:2:.+:[0-9.]+$/ */
  schema_id?: string;
  /** @default "proposal_sent" */
  state?:
    | "proposal_sent"
    | "proposal_received"
    | "offer_sent"
    | "offer_received"
    | "request_sent"
    | "request_received"
    | "credential_issued"
    | "credential_received"
    | "credential_acked"
    | "credential_revoked"
    | "abandoned"
    | "deleted";
  thread_id?: string;
  trace?: boolean;
  /** @pattern /^\\d{4}-\\d\\d-\\d\\d[T ]\\d\\d:\\d\\d(?:\\:(?:\\d\\d(?:\\.\\d{1,6})?))?(?:[+-]\\d\\d:?\\d\\d|Z|)$/ */
  updated_at?: string;
}

export interface V10CredentialExchangeCredential {
  attrs?: Record<string, string>;
  /** @pattern /^([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}):3:CL:(([1-9][0-9]*)|([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}:2:.+:[0-9.]+)):(.+)?$/ */
  cred_def_id?: string;
  /** @pattern /^[1-9][0-9]*$/ */
  cred_rev_id?: string;
  referent?: string;
  /** @pattern /^([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}):4:([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}):3:CL:(([1-9][0-9]*)|([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}:2:.+:[0-9.]+))(:.+)?:CL_ACCUM:(.+$)/ */
  rev_reg_id?: string;
  /** @pattern /^[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}:2:.+:[0-9.]+$/ */
  schema_id?: string;
}

export interface V10CredentialExchangeCredentialOffer {
  /**
   * @minLength 1
   * @pattern /^([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}):3:CL:(([1-9][0-9]*)|([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}:2:.+:[0-9.]+)):(.+)?$/
   */
  cred_def_id: string;
  key_correctness_proof: IndyCredAbstractKeyCorrectnessProof;
  /**
   * @minLength 1
   * @pattern /^[0-9]*$/
   */
  nonce: string;
  /**
   * @minLength 1
   * @pattern /^[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}:2:.+:[0-9.]+$/
   */
  schema_id: string;
}

export interface V10CredentialExchangeCredentialOfferDict {
  "@id"?: string;
  "@type"?: string;
  comment?: string;
  credential_preview?: CredentialPreview;
  "offers~attach": AttachDecorator[];
}

export interface V10CredentialExchangeCredentialProposalDict {
  "@id"?: string;
  "@type"?: string;
  comment?: string;
  /** @pattern /^([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}):3:CL:(([1-9][0-9]*)|([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}:2:.+:[0-9.]+)):(.+)?$/ */
  cred_def_id?: string;
  credential_proposal?: CredentialPreview;
  /** @pattern /^(did:sov:)?[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}$/ */
  issuer_did?: string;
  /** @pattern /^[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}:2:.+:[0-9.]+$/ */
  schema_id?: string;
  /** @pattern /^(did:sov:)?[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}$/ */
  schema_issuer_did?: string;
  schema_name?: string;
  /** @pattern /^[0-9.]+$/ */
  schema_version?: string;
}

export interface V10CredentialExchangeCredentialRequest {
  blinded_ms: object;
  blinded_ms_correctness_proof: object;
  /**
   * @minLength 1
   * @pattern /^([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}):3:CL:(([1-9][0-9]*)|([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}:2:.+:[0-9.]+)):(.+)?$/
   */
  cred_def_id: string;
  /**
   * @minLength 1
   * @pattern /^[0-9]*$/
   */
  nonce: string;
  /** @pattern /^(did:sov:)?[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}$/ */
  prover_did?: string;
}

export interface V10CredentialExchangeListResult {
  results?: V10CredentialExchange[];
}

export interface V10CredentialExchangeRawCredential {
  /**
   * @minLength 1
   * @pattern /^([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}):3:CL:(([1-9][0-9]*)|([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}:2:.+:[0-9.]+)):(.+)?$/
   */
  cred_def_id: string;
  rev_reg?: object;
  /** @pattern /^([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}):4:([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}):3:CL:(([1-9][0-9]*)|([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}:2:.+:[0-9.]+))(:.+)?:CL_ACCUM:(.+$)/ */
  rev_reg_id?: string;
  /**
   * @minLength 1
   * @pattern /^[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}:2:.+:[0-9.]+$/
   */
  schema_id: string;
  signature: object;
  signature_correctness_proof: object;
  values: Record<string, object>;
  witness?: object;
}

export interface V10PresentationExchange {
  auto_present?: boolean;
  auto_verify?: boolean;
  connection_id?: string;
  /** @pattern /^\\d{4}-\\d\\d-\\d\\d[T ]\\d\\d:\\d\\d(?:\\:(?:\\d\\d(?:\\.\\d{1,6})?))?(?:[+-]\\d\\d:?\\d\\d|Z|)$/ */
  created_at?: string;
  error_msg?: string;
  /** @default "self" */
  initiator?: "self" | "external";
  presentation?: V10PresentationExchangePresentation;
  presentation_exchange_id?: string;
  presentation_proposal_dict?: V10PresentationExchangePresentationProposalDict;
  presentation_request?: V10PresentationExchangePresentationRequest;
  presentation_request_dict?: V10PresentationExchangePresentationRequestDict;
  /** @default "prover" */
  role?: "prover" | "verifier";
  /** @default "proposal_sent" */
  state?:
    | "proposal_sent"
    | "proposal_received"
    | "request_sent"
    | "request_received"
    | "presentation_sent"
    | "presentation_received"
    | "verified"
    | "presentation_acked"
    | "abandoned";
  thread_id?: string;
  trace?: boolean;
  /** @pattern /^\\d{4}-\\d\\d-\\d\\d[T ]\\d\\d:\\d\\d(?:\\:(?:\\d\\d(?:\\.\\d{1,6})?))?(?:[+-]\\d\\d:?\\d\\d|Z|)$/ */
  updated_at?: string;
  /** @default "true" */
  verified?: "true" | "false";
  verified_msgs?: string[];
}

export interface V10PresentationExchangePresentation {
  identifiers?: IndyProofIdentifier[];
  proof?: IndyProofProof;
  requested_proof?: IndyProofRequestedProof;
}

export interface V10PresentationExchangePresentationProposalDict {
  "@id"?: string;
  "@type"?: string;
  comment?: string;
  presentation_proposal: IndyPresPreview;
}

export interface V10PresentationExchangePresentationRequest {
  name?: string;
  non_revoked?: IndyProofReqAttrSpecNonRevoked;
  /** @pattern /^[1-9][0-9]*$/ */
  nonce?: string;
  requested_attributes: Record<string, IndyProofReqAttrSpec>;
  requested_predicates: Record<string, IndyProofReqPredSpec>;
  /** @pattern /^[0-9.]+$/ */
  version?: string;
}

export interface V10PresentationExchangePresentationRequestDict {
  "@id"?: string;
  "@type"?: string;
  comment?: string;
  "request_presentations~attach": AttachDecorator[];
}

export interface V10PresentationProblemReportRequest {
  /** @minLength 1 */
  description: string;
}

export interface V10PresentationSendRequestRequest {
  auto_verify?: boolean;
  comment?: string;
  /**
   * @format uuid
   * @minLength 1
   */
  connection_id: string;
  proof_request: IndyProofRequest;
  trace?: boolean;
}

export type V10PresentProofModuleResponse = object;

export interface V20CredExRecord {
  auto_issue?: boolean;
  auto_offer?: boolean;
  auto_remove?: boolean;
  by_format?: V20CredExRecordByFormat;
  connection_id?: string;
  /** @pattern /^\\d{4}-\\d\\d-\\d\\d[T ]\\d\\d:\\d\\d(?:\\:(?:\\d\\d(?:\\.\\d{1,6})?))?(?:[+-]\\d\\d:?\\d\\d|Z|)$/ */
  created_at?: string;
  cred_ex_id?: string;
  cred_issue?: V20CredExRecordCredIssue;
  cred_offer?: V10CredentialExchangeCredentialOfferDict;
  cred_preview?: V20CredExRecordCredPreview;
  cred_proposal?: V10CredentialExchangeCredentialProposalDict;
  cred_request?: V20CredExRecordCredRequest;
  error_msg?: string;
  /** @default "self" */
  initiator?: "self" | "external";
  parent_thread_id?: string;
  /** @default "issuer" */
  role?: "issuer" | "holder";
  /** @default "proposal-sent" */
  state?:
    | "proposal-sent"
    | "proposal-received"
    | "offer-sent"
    | "offer-received"
    | "request-sent"
    | "request-received"
    | "credential-issued"
    | "credential-received"
    | "done"
    | "credential-revoked"
    | "abandoned";
  thread_id?: string;
  trace?: boolean;
  /** @pattern /^\\d{4}-\\d\\d-\\d\\d[T ]\\d\\d:\\d\\d(?:\\:(?:\\d\\d(?:\\.\\d{1,6})?))?(?:[+-]\\d\\d:?\\d\\d|Z|)$/ */
  updated_at?: string;
}

export type V20CredExRecordByFormat = object;

export type V20CredExRecordCredIssue = object;

export type V20CredExRecordCredPreview = object;

export type V20CredExRecordCredRequest = object;

export interface V20CredExRecordDetail {
  cred_ex_record?: V20CredExRecordDetailCredExRecord;
  indy?: V20CredExRecordIndy;
  ld_proof?: V20CredExRecordLDProof;
}

export interface V20CredExRecordDetailCredExRecord {
  auto_issue?: boolean;
  auto_offer?: boolean;
  auto_remove?: boolean;
  by_format?: V20CredExRecordByFormat;
  connection_id?: string;
  /** @pattern /^\\d{4}-\\d\\d-\\d\\d[T ]\\d\\d:\\d\\d(?:\\:(?:\\d\\d(?:\\.\\d{1,6})?))?(?:[+-]\\d\\d:?\\d\\d|Z|)$/ */
  created_at?: string;
  cred_ex_id?: string;
  cred_issue?: V20CredExRecordCredIssue;
  cred_offer?: V10CredentialExchangeCredentialOfferDict;
  cred_preview?: V20CredExRecordCredPreview;
  cred_proposal?: V10CredentialExchangeCredentialProposalDict;
  cred_request?: V20CredExRecordCredRequest;
  error_msg?: string;
  /** @default "self" */
  initiator?: "self" | "external";
  parent_thread_id?: string;
  /** @default "issuer" */
  role?: "issuer" | "holder";
  /** @default "proposal-sent" */
  state?:
    | "proposal-sent"
    | "proposal-received"
    | "offer-sent"
    | "offer-received"
    | "request-sent"
    | "request-received"
    | "credential-issued"
    | "credential-received"
    | "done"
    | "credential-revoked"
    | "abandoned";
  thread_id?: string;
  trace?: boolean;
  /** @pattern /^\\d{4}-\\d\\d-\\d\\d[T ]\\d\\d:\\d\\d(?:\\:(?:\\d\\d(?:\\.\\d{1,6})?))?(?:[+-]\\d\\d:?\\d\\d|Z|)$/ */
  updated_at?: string;
}

export interface V20CredExRecordIndy {
  /** @pattern /^\\d{4}-\\d\\d-\\d\\d[T ]\\d\\d:\\d\\d(?:\\:(?:\\d\\d(?:\\.\\d{1,6})?))?(?:[+-]\\d\\d:?\\d\\d|Z|)$/ */
  created_at?: string;
  cred_ex_id?: string;
  cred_ex_indy_id?: string;
  cred_id_stored?: string;
  cred_request_metadata?: object;
  /** @pattern /^[1-9][0-9]*$/ */
  cred_rev_id?: string;
  /** @pattern /^([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}):4:([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}):3:CL:(([1-9][0-9]*)|([123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{21,22}:2:.+:[0-9.]+))(:.+)?:CL_ACCUM:(.+$)/ */
  rev_reg_id?: string;
  state?: string;
  /** @pattern /^\\d{4}-\\d\\d-\\d\\d[T ]\\d\\d:\\d\\d(?:\\:(?:\\d\\d(?:\\.\\d{1,6})?))?(?:[+-]\\d\\d:?\\d\\d|Z|)$/ */
  updated_at?: string;
}

export interface V20CredExRecordLDProof {
  /** @pattern /^\\d{4}-\\d\\d-\\d\\d[T ]\\d\\d:\\d\\d(?:\\:(?:\\d\\d(?:\\.\\d{1,6})?))?(?:[+-]\\d\\d:?\\d\\d|Z|)$/ */
  created_at?: string;
  cred_ex_id?: string;
  cred_ex_ld_proof_id?: string;
  cred_id_stored?: string;
  state?: string;
  /** @pattern /^\\d{4}-\\d\\d-\\d\\d[T ]\\d\\d:\\d\\d(?:\\:(?:\\d\\d(?:\\.\\d{1,6})?))?(?:[+-]\\d\\d:?\\d\\d|Z|)$/ */
  updated_at?: string;
}

export interface V20PresExRecord {
  auto_present?: boolean;
  auto_verify?: boolean;
  by_format?: V20PresExRecordByFormat;
  connection_id?: string;
  /** @pattern /^\\d{4}-\\d\\d-\\d\\d[T ]\\d\\d:\\d\\d(?:\\:(?:\\d\\d(?:\\.\\d{1,6})?))?(?:[+-]\\d\\d:?\\d\\d|Z|)$/ */
  created_at?: string;
  error_msg?: string;
  /** @default "self" */
  initiator?: "self" | "external";
  pres?: V20PresExRecordPres;
  pres_ex_id?: string;
  pres_proposal?: V10PresentationExchangePresentationProposalDict;
  pres_request?: V10PresentationExchangePresentationRequestDict;
  /** @default "prover" */
  role?: "prover" | "verifier";
  /** @default "proposal-sent" */
  state?:
    | "proposal-sent"
    | "proposal-received"
    | "request-sent"
    | "request-received"
    | "presentation-sent"
    | "presentation-received"
    | "done"
    | "abandoned";
  thread_id?: string;
  trace?: boolean;
  /** @pattern /^\\d{4}-\\d\\d-\\d\\d[T ]\\d\\d:\\d\\d(?:\\:(?:\\d\\d(?:\\.\\d{1,6})?))?(?:[+-]\\d\\d:?\\d\\d|Z|)$/ */
  updated_at?: string;
  /** @default "true" */
  verified?: "true" | "false";
  verified_msgs?: string[];
}

export type V20PresExRecordByFormat = object;

export type V20PresExRecordPres = object;

export interface VcObject {
  type?: string[];
  "@context"?: string[];
  id?: string;
  issuer?: string;
  /** @format date-time */
  issuanceDate?: string;
  /** @format date-time */
  issued?: string;
  /** @format date-time */
  validFrom?: string;
  /** @format date-time */
  validUntil?: string;
  credentialSchema?: CredentialSchema;
  credentialSubject?: object;
}

export interface VcRoot {
  type?: string[];
  "@context"?: string[];
  id?: string;
  issuer?: string;
  /** @format date-time */
  issuanceDate?: string;
  /** @format date-time */
  issued?: string;
  /** @format date-time */
  validFrom?: string;
  /** @format date-time */
  validUntil?: string;
  credentialSchema?: CredentialSchema;
  credentialSubject?: Root;
}

export interface VcRecord {
  contexts?: string[];
  cred_tags?: Record<string, string>;
  cred_value?: object;
  expanded_types?: string[];
  given_id?: string;
  issuer_id?: string;
  proof_types?: string[];
  record_id?: string;
  schema_ids?: string[];
  subject_ids?: string[];
}

export interface WalletInfo {
  ledger?: LedgerConfig;
  dids?: Did[];
  publicDID?: Did;
  webDID?: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "http://localhost:7072/api";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title OpenAPI Document on Azure Functions
 * @version 1.0.0
 * @baseUrl http://localhost:7072/api
 *
 * This is the OpenAPI Document on Azure Functions
 */
export class WalletsAPI<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  wallet = {
    /**
     * No description
     *
     * @tags WaltidWalletController
     * @name WaltidWalletControllerLogin
     * @request GET:/wallet/login
     */
    waltidWalletControllerLogin: (params: RequestParams = {}) =>
      this.request<UserInfo, void>({
        path: `/wallet/login`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WaltidWalletController
     * @name WaltidWalletControllerListCredentials
     * @request GET:/wallet/credentials/list
     */
    waltidWalletControllerListCredentials: (params: RequestParams = {}) =>
      this.request<CredentialDefinitionValue, string>({
        path: `/wallet/credentials/list`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WaltidWalletController
     * @name WaltidWalletControllerDeleteCredential
     * @request DELETE:/wallet/credentials/delete/{id}
     */
    waltidWalletControllerDeleteCredential: (id: string, params: RequestParams = {}) =>
      this.request<void, string>({
        path: `/wallet/credentials/delete/${id}`,
        method: "DELETE",
        ...params,
      }),

    /**
     * No description
     *
     * @tags WaltidWalletController
     * @name WaltidWalletControllerInitiateIssuance
     * @request GET:/wallet/{walletName}/initiateIssuance
     */
    waltidWalletControllerInitiateIssuance: (
      walletName: string,
      query: {
        /** Company ID */
        issuer: string;
        /** Company ID */
        credential_type: string;
        /** Company ID */
        "pre-authorized_code": string;
        /** Company ID */
        user_pin_required: string;
        /** Company ID */
        op_state: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, string>({
        path: `/wallet/${walletName}/initiateIssuance`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags WaltidWalletController
     * @name WaltidWalletControllerInitiatePresentation
     * @request GET:/wallet/{walletName}/initiatePresentation
     */
    waltidWalletControllerInitiatePresentation: (
      walletName: string,
      query: {
        /** Scope */
        scope: string;
        /** Presentation definition */
        presentation_definition: string;
        /** Response type */
        response_type: string;
        /** Redirect uri */
        redirect_uri: string;
        /** State */
        state: string;
        /** Nonce */
        nonce: string;
        /** Client ID */
        client_id: string;
        /** Response mode */
        response_mode: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, string>({
        path: `/wallet/${walletName}/initiatePresentation`,
        method: "GET",
        query: query,
        ...params,
      }),
  };
  prh = {
    /**
     * No description
     *
     * @tags PRH
     * @name PrhControllerSendCredentialWithWebDid
     * @request POST:/PRH/SendCredentialWithWebDid
     */
    prhControllerSendCredentialWithWebDid: (
      query: {
        /** Type of credential, for example 'TODO eudtrc' */
        credentialType: string;
        /** Web DID */
        webDid: string;
      },
      body: string,
      params: RequestParams = {},
    ) =>
      this.request<EudtrcIssuanceResponse, string>({
        path: `/PRH/SendCredentialWithWebDid`,
        method: "POST",
        query: query,
        body: body,
        type: ContentType.Text,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PRH
     * @name PrhRequestCredentials
     * @request POST:/PRH/RequestCredentials
     */
    prhRequestCredentials: (
      query: {
        /** Type of credential, for example 'lpid' */
        credentialTypes: string[];
      },
      body: string,
      params: RequestParams = {},
    ) =>
      this.request<CredentialsIssuanceResponse[], string>({
        path: `/PRH/RequestCredentials`,
        method: "POST",
        query: query,
        body: body,
        type: ContentType.Text,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PRH
     * @name PrhSendCredentials
     * @request POST:/PRH/SendCredentials
     */
    prhSendCredentials: (
      query: {
        /** Type of credential, for example 'lpid' */
        credentialTypes: string[];
        /** Web DID */
        webDid: string;
      },
      body: CredentialsIssuanceResponse[],
      params: RequestParams = {},
    ) =>
      this.request<string, string>({
        path: `/PRH/SendCredentials`,
        method: "POST",
        query: query,
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  };
  pankki = {
    /**
     * No description
     *
     * @tags Pankki
     * @name StartCredentialsPresentationRequestWithWebDid
     * @request POST:/pankki/StartCredentialsPresentationRequestWithWebDid
     */
    startCredentialsPresentationRequestWithWebDid: (
      query: {
        /** Web DID */
        webDid: string;
        /** Comma separated credential names for presentation request. (case sensitive) */
        credentialNames: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, any>({
        path: `/pankki/StartCredentialsPresentationRequestWithWebDid`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pankki
     * @name StartEudtrcPresentationRequestWithWebDid
     * @request POST:/pankki/StartEudtrcPresentationRequestWithWebDid
     */
    startEudtrcPresentationRequestWithWebDid: (
      query: {
        /** Web DID */
        webDid: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<any, any>({
        path: `/pankki/StartEudtrcPresentationRequestWithWebDid`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pankki
     * @name OnVerifyPresentationSuccess
     * @request POST:/pankki/OnVerifyPresentationSuccess/{state}
     */
    onVerifyPresentationSuccess: (state: string, body: string, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/pankki/OnVerifyPresentationSuccess/${state}`,
        method: "POST",
        body: body,
        type: ContentType.Text,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pankki
     * @name PollPresentationRequest
     * @request GET:/pankki/poll
     * @secure
     */
    pollPresentationRequest: (
      query: {
        /** RequestId to poll */
        requestId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<string, string>({
        path: `/pankki/poll`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pankki
     * @name GetVerifiedCertificatesByState
     * @request GET:/pankki/vc
     * @secure
     */
    getVerifiedCertificatesByState: (
      query: {
        /** RequestId to poll */
        requestId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<VcObject[], string>({
        path: `/pankki/vc`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pankki
     * @name GetVerifiedCertificateByState
     * @request GET:/pankki/vc/etrc
     * @secure
     */
    getVerifiedCertificateByState: (
      query: {
        /** RequestId to poll */
        requestId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<VcRoot[], string>({
        path: `/pankki/vc/etrc`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  webhook = {
    /**
     * No description
     *
     * @tags Event listeners
     * @name Webhook
     * @request POST:/webhook/topic/{topic}
     */
    webhook: (topic: string, body: object, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/webhook/topic/${topic}`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  };
  companyregister = {
    /**
     * No description
     *
     * @tags Event listeners
     * @name Run
     * @request POST:/companyregister/webhook/topic/{topic}
     */
    run: (topic: string, body: object, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/companyregister/webhook/topic/${topic}`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),
  };
  store = {
    /**
     * No description
     *
     * @tags Event listeners
     * @name Run
     * @request POST:/store/webhook/topic/{topic}
     */
    run: (topic: string, body: object, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/store/webhook/topic/${topic}`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name StoreControllerGetConnection
     * @request GET:/Store/GetConnection
     * @secure
     */
    storeControllerGetConnection: (
      query: {
        /** Connection ID */
        connectionId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ConnRecord, string>({
        path: `/Store/GetConnection`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name StoreControllerGetConnectionByInvitationMsgId
     * @request GET:/Store/GetConnectionByInvitationMsgId
     * @secure
     */
    storeControllerGetConnectionByInvitationMsgId: (
      query: {
        /** Invitation Message ID */
        invitationMsgId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ConnRecord, string>({
        path: `/Store/GetConnectionByInvitationMsgId`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name StoreControllerGetCreatePublicDidConnection
     * @request POST:/Store/GetCreatePublicDidConnection
     * @secure
     */
    storeControllerGetCreatePublicDidConnection: (
      query: {
        /** Public DID */
        did: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ConnRecord, string>({
        path: `/Store/GetCreatePublicDidConnection`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name StoreControllerCreateInvitation
     * @request POST:/Store/CreateInvitation
     */
    storeControllerCreateInvitation: (params: RequestParams = {}) =>
      this.request<InvitationResult, string>({
        path: `/Store/CreateInvitation`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name StoreControllerCreateOobInvitation
     * @request POST:/Store/CreateOOBInvitation
     */
    storeControllerCreateOobInvitation: (params: RequestParams = {}) =>
      this.request<InvitationRecord, string>({
        path: `/Store/CreateOOBInvitation`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name StoreControllerSubmitPurchase
     * @request POST:/Store/SubmitPurchase
     */
    storeControllerSubmitPurchase: (
      query: {
        /** Connection ID */
        connId: string;
      },
      body: Purchase,
      params: RequestParams = {},
    ) =>
      this.request<EReceiptIssuanceResponse, string>({
        path: `/Store/SubmitPurchase`,
        method: "POST",
        query: query,
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name StoreControllerSendReceiptWithWebDid
     * @request POST:/Store/SendReceiptWithWebDid
     */
    storeControllerSendReceiptWithWebDid: (
      query: {
        /** Connection ID */
        webDid: string;
      },
      body: Purchase,
      params: RequestParams = {},
    ) =>
      this.request<EReceiptIssuanceResponse, string>({
        path: `/Store/SendReceiptWithWebDid`,
        method: "POST",
        query: query,
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name StoreControllerCreateOidcIssuanceRequest
     * @request POST:/Store/CreateOIDCIssuanceRequest
     */
    storeControllerCreateOidcIssuanceRequest: (body: Purchase, params: RequestParams = {}) =>
      this.request<EReceiptIssuanceResponse, string>({
        path: `/Store/CreateOIDCIssuanceRequest`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name StoreControllerResolveOidcIssuanceEndpoint
     * @request GET:/Store/ResolveOIDCIssuanceEndpoint
     */
    storeControllerResolveOidcIssuanceEndpoint: (
      query: {
        /** Web wallet url */
        walletUrl: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ConnRecord, string>({
        path: `/Store/ResolveOIDCIssuanceEndpoint`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name StoreGetIssuedCredentials
     * @request GET:/Store/GetIssuedCredentials
     * @secure
     */
    storeGetIssuedCredentials: (
      query: {
        /** Connection ID */
        connId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<V10CredentialExchangeListResult, string>({
        path: `/Store/GetIssuedCredentials`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Store
     * @name StoreControllerCreateRemppaVasaraProofRequest
     * @request POST:/Store/CreateRemppaVasaraProofRequest
     * @secure
     */
    storeControllerCreateRemppaVasaraProofRequest: (params: RequestParams = {}) =>
      this.request<V10PresentationExchange, string>({
        path: `/Store/CreateRemppaVasaraProofRequest`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Requires Admin level authorization
     *
     * @tags Store
     * @name StoreControllerDeleteCredentialExchanges
     * @request POST:/Store/DeleteCredentialExchanges
     * @secure
     */
    storeControllerDeleteCredentialExchanges: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/Store/DeleteCredentialExchanges`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * @description Requires Admin level authorization
     *
     * @tags Store
     * @name StoreControllerDeleteConnections
     * @request POST:/Store/DeleteConnections
     * @secure
     */
    storeControllerDeleteConnections: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/Store/DeleteConnections`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
  cloudWallet = {
    /**
     * No description
     *
     * @tags BasicMessages
     * @name BasicMessagesControllerGetBasicMessages
     * @request GET:/CloudWallet/GetBasicMessages
     */
    basicMessagesControllerGetBasicMessages: (
      query: {
        /** Company ID */
        companyID: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BasicMessageWrapper[], string>({
        path: `/CloudWallet/GetBasicMessages`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BasicMessages
     * @name BasicMessagesControllerSendBasicMessage
     * @request POST:/CloudWallet/SendBasicMessage
     */
    basicMessagesControllerSendBasicMessage: (
      query: {
        /** Company ID */
        companyID: string;
      },
      body: BasicMessage,
      params: RequestParams = {},
    ) =>
      this.request<void, string>({
        path: `/CloudWallet/SendBasicMessage`,
        method: "POST",
        query: query,
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Connections
     * @name ConnectionsControllerListConnections
     * @request GET:/CloudWallet/ListConnections
     */
    connectionsControllerListConnections: (
      query: {
        /** Company ID */
        companyID: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ConnectionList, string>({
        path: `/CloudWallet/ListConnections`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Connections
     * @name ConnectionsControllerGetConnection
     * @request GET:/CloudWallet/GetConnection
     */
    connectionsControllerGetConnection: (
      query: {
        /** Company ID */
        companyID: string;
        /** Connection ID */
        connectionId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ConnRecord, string>({
        path: `/CloudWallet/GetConnection`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Connections
     * @name ConnectionsControllerCreateInvitation
     * @request POST:/CloudWallet/CreateInvitation
     */
    connectionsControllerCreateInvitation: (
      query: {
        /** Company ID */
        companyID: string;
        /** Alias for connection */
        alias?: string;
        /** Create multi-use or single-use invitation. Default is multi-use (true). */
        "multi-use"?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<InvitationResult, string>({
        path: `/CloudWallet/CreateInvitation`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Connections
     * @name ConnectionsControllerReceiveConnection
     * @request POST:/CloudWallet/ReceiveConnection
     */
    connectionsControllerReceiveConnection: (
      query: {
        /** Company ID */
        companyID: string;
        /** Invite URL */
        inviteUrl: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ConnRecord, string>({
        path: `/CloudWallet/ReceiveConnection`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Connections
     * @name ConnectionsControllerGetCreatePublicDidConnection
     * @request POST:/CloudWallet/GetCreatePublicDidConnection
     */
    connectionsControllerGetCreatePublicDidConnection: (
      query: {
        /** Company ID */
        companyID: string;
        /** Public DID */
        did: string;
        /** Alias */
        alias?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ConnRecord, string>({
        path: `/CloudWallet/GetCreatePublicDidConnection`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Connections
     * @name ConnectionsControllerDeleteConnection
     * @request POST:/CloudWallet/DeleteConnection
     */
    connectionsControllerDeleteConnection: (
      query: {
        /** Company ID */
        companyID: string;
        /** Connection id */
        connectionId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, string>({
        path: `/CloudWallet/DeleteConnection`,
        method: "POST",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Connections
     * @name ConnectionsControllerPingConnection
     * @request POST:/CloudWallet/PingConnection
     */
    connectionsControllerPingConnection: (body: string[], params: RequestParams = {}) =>
      this.request<void, PingResponse[]>({
        path: `/CloudWallet/PingConnection`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Credentials
     * @name CredentialsControllerListCredentials
     * @request GET:/CloudWallet/ListCredentials
     */
    credentialsControllerListCredentials: (
      query: {
        /** Company ID */
        companyID: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CredInfoList, string>({
        path: `/CloudWallet/ListCredentials`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Issue Credential v1.0
     * @name CredentialsControllerListCredentialExchanges
     * @request GET:/CloudWallet/ListCredentialExchanges
     */
    credentialsControllerListCredentialExchanges: (
      query?: {
        /** Company ID */
        companyID?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CredentialExchangeResponses, string>({
        path: `/CloudWallet/ListCredentialExchanges`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Credentials
     * @name CredentialsControllerListCredentialsWithExchange
     * @request GET:/CloudWallet/ListCredentialsWithExchange
     */
    credentialsControllerListCredentialsWithExchange: (
      query: {
        /** Company ID */
        companyID: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CredentialResponse, string>({
        path: `/CloudWallet/ListCredentialsWithExchange`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Issue Credential v1.0
     * @name CredentialsControllerAcceptCredentialOffer
     * @request POST:/CloudWallet/AcceptCredentialOffer
     */
    credentialsControllerAcceptCredentialOffer: (
      query: {
        /** Company ID */
        companyID: string;
        /** Credential exchange id */
        credExId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ConnRecord, string>({
        path: `/CloudWallet/AcceptCredentialOffer`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Present Proof v1.0
     * @name CredentialsControllerListAvailableCredentialsForProofRequest
     * @request GET:/CloudWallet/ListAvailableCredentialsForProofRequest
     */
    credentialsControllerListAvailableCredentialsForProofRequest: (
      query: {
        /** Company ID */
        companyID: string;
        /** Presentation exchange id */
        presExId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<IndyCredPrecis, string>({
        path: `/CloudWallet/ListAvailableCredentialsForProofRequest`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Credentials
     * @name CredentialsControllerDeleteCredential
     * @request POST:/CloudWallet/DeleteCredential
     */
    credentialsControllerDeleteCredential: (
      query: {
        /** Company ID */
        companyID: string;
        /** Credential id */
        credentialId: string;
        /** W3C credential id */
        w3cCredentialId: string;
        /** Credential exchange id */
        exchangeId?: string;
        /** V2.0 Credential exchange id */
        v20credExId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ConnRecord, string>({
        path: `/CloudWallet/DeleteCredential`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Credentials
     * @name CredentialsControllerIssueCredential
     * @request POST:/CloudWallet/IssueCredential
     */
    credentialsControllerIssueCredential: (
      query: {
        /** Company ID */
        companyID: string;
        /** Connection ID */
        connectionId: string;
        /** Schema ID */
        schemaId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<V20CredExRecord, string>({
        path: `/CloudWallet/IssueCredential`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Credentials
     * @name CredentialsControllerGetSchema
     * @request GET:/CloudWallet/GetSchema
     */
    credentialsControllerGetSchema: (
      query: {
        /** Company ID */
        companyID: string;
        /** Schema ID */
        schema_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SchemaGetResult, string>({
        path: `/CloudWallet/GetSchema`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Credentials
     * @name CredentialsControllerGetCredentialDefinition
     * @request GET:/CloudWallet/GetCredentialDefinition
     */
    credentialsControllerGetCredentialDefinition: (
      query: {
        /** Company ID */
        companyID: string;
        /** Credential definition ID */
        cred_def_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CredentialDefinitionGetResult, string>({
        path: `/CloudWallet/GetCredentialDefinition`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Credentials
     * @name CredentialsControllerGetAvailableSchemas
     * @request GET:/CloudWallet/GetAvailableSchemas
     */
    credentialsControllerGetAvailableSchemas: (params: RequestParams = {}) =>
      this.request<AvailableRecord[], string>({
        path: `/CloudWallet/GetAvailableSchemas`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Credentials
     * @name CredentialsControllerGetAvailableCredDefs
     * @request GET:/CloudWallet/GetAvailableCredDefs
     */
    credentialsControllerGetAvailableCredDefs: (params: RequestParams = {}) =>
      this.request<AvailableRecord[], string>({
        path: `/CloudWallet/GetAvailableCredDefs`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Verifications
     * @name PresentationsControllerListPresentationExchanges
     * @request GET:/CloudWallet/ListPresentationExchanges
     */
    presentationsControllerListPresentationExchanges: (params: RequestParams = {}) =>
      this.request<PresentationResponses, string | void>({
        path: `/CloudWallet/ListPresentationExchanges`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Verifications
     * @name PresentationsControllerGetV10PresentationExchange
     * @request GET:/CloudWallet/GetV10PresentationExchange
     */
    presentationsControllerGetV10PresentationExchange: (
      query: {
        /** Presentation Exchange ID */
        presExId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PresentationResponses, string | void>({
        path: `/CloudWallet/GetV10PresentationExchange`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Verifications
     * @name PresentationsControllerSendProofRequest
     * @request POST:/CloudWallet/SendProofRequest
     */
    presentationsControllerSendProofRequest: (body: V10PresentationSendRequestRequest, params: RequestParams = {}) =>
      this.request<V10PresentationExchange, string>({
        path: `/CloudWallet/SendProofRequest`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Verifications
     * @name PresentationsControllerSendPresentation
     * @request POST:/CloudWallet/SendPresentation
     */
    presentationsControllerSendPresentation: (
      query: {
        /** Presentation Exchange ID */
        presExId: string;
      },
      body: IndyPresSpec,
      params: RequestParams = {},
    ) =>
      this.request<V10PresentationExchange, string>({
        path: `/CloudWallet/SendPresentation`,
        method: "POST",
        query: query,
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Verifications
     * @name PresentationsControllerRejectProofRequest
     * @request POST:/CloudWallet/RejectProofRequest
     */
    presentationsControllerRejectProofRequest: (
      query: {
        /** Presentation Exchange ID */
        presExId: string;
      },
      body: V10PresentationProblemReportRequest,
      params: RequestParams = {},
    ) =>
      this.request<V10PresentProofModuleResponse, string>({
        path: `/CloudWallet/RejectProofRequest`,
        method: "POST",
        query: query,
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Verifications
     * @name PresentationsControllerVerifyPresentation
     * @request POST:/CloudWallet/VerifyPresentation
     */
    presentationsControllerVerifyPresentation: (
      query: {
        /** Presentation Exchange ID */
        presExId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<V10PresentationExchange, string>({
        path: `/CloudWallet/VerifyPresentation`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wallets
     * @name WalletControllerGetWallet
     * @request GET:/CloudWallet/GetWallet
     */
    walletControllerGetWallet: (
      query: {
        /** Company code (Y-tunnus) */
        companyID: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DidList, string>({
        path: `/CloudWallet/GetWallet`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wallets
     * @name WalletControllerGetWalletInfo
     * @request GET:/CloudWallet/GetWalletInfo
     */
    walletControllerGetWalletInfo: (
      query: {
        /** Company code (Y-tunnus) */
        companyID: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<WalletInfo, string>({
        path: `/CloudWallet/GetWalletInfo`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  companyRegister = {
    /**
     * No description
     *
     * @tags CompanyRegister
     * @name CompanyRegisterControllerGetConnection
     * @request GET:/CompanyRegister/GetConnection
     * @secure
     */
    companyRegisterControllerGetConnection: (
      query: {
        /** Company ID */
        companyID: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ConnRecord, string>({
        path: `/CompanyRegister/GetConnection`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyRegister
     * @name CompanyRegisterControllerCreateInvitation
     * @request POST:/CompanyRegister/CreateInvitation
     * @secure
     */
    companyRegisterControllerCreateInvitation: (
      query: {
        /** Company ID */
        companyID: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<InvitationResult, string>({
        path: `/CompanyRegister/CreateInvitation`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyRegister
     * @name CompanyRegisterControllerReceiveConnection
     * @request POST:/CompanyRegister/ReceiveConnection
     * @secure
     */
    companyRegisterControllerReceiveConnection: (
      query: {
        /** Company ID */
        companyID: string;
      },
      body: string,
      params: RequestParams = {},
    ) =>
      this.request<ConnRecord, string>({
        path: `/CompanyRegister/ReceiveConnection`,
        method: "POST",
        query: query,
        body: body,
        secure: true,
        type: ContentType.Text,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyRegister
     * @name CompanyRegisterControllerGetIssuedCredentials
     * @request GET:/CompanyRegister/GetIssuedCredentials
     * @secure
     */
    companyRegisterControllerGetIssuedCredentials: (
      query: {
        /** Connection ID */
        connId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<V10CredentialExchangeListResult, string>({
        path: `/CompanyRegister/GetIssuedCredentials`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyRegister
     * @name CompanyRegisterControllerGetAvailableCredentials
     * @request GET:/CompanyRegister/GetAvailableCredentials
     * @secure
     */
    companyRegisterControllerGetAvailableCredentials: (params: RequestParams = {}) =>
      this.request<AvailableCredential[], string>({
        path: `/CompanyRegister/GetAvailableCredentials`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyRegister
     * @name CompanyRegisterControllerIssueCredential
     * @request POST:/CompanyRegister/IssueCredential
     * @secure
     */
    companyRegisterControllerIssueCredential: (
      query: {
        /** Connection ID */
        connId: string;
        /** Credential definition ID */
        credDefId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<V10CredentialExchange, string>({
        path: `/CompanyRegister/IssueCredential`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyRegister
     * @name CompanyRegisterControllerIssueJsonLdCredential
     * @request POST:/CompanyRegister/IssueJsonLDCredential
     * @secure
     */
    companyRegisterControllerIssueJsonLdCredential: (
      query: {
        /** Connection ID */
        connId: string;
        /** Credential label */
        credLabel: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<V20CredExRecord, string>({
        path: `/CompanyRegister/IssueJsonLDCredential`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags CompanyRegister
     * @name CompanyRegisterControllerSendCompanyIdProofRequest
     * @request POST:/CompanyRegister/SendCompanyIDProofRequest
     * @secure
     */
    companyRegisterControllerSendCompanyIdProofRequest: (
      query: {
        /** Connection ID */
        connId: string;
        /** Credential definition ID */
        credDefId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<V10PresentationExchange, string>({
        path: `/CompanyRegister/SendCompanyIDProofRequest`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Use admin api key for this endpoint.
     *
     * @tags Admin
     * @name CompanyRegisterControllerResetForDemo
     * @request GET:/CompanyRegister/ResetForDemo
     * @secure
     */
    companyRegisterControllerResetForDemo: (
      query: {
        /**
         * Company
         * @default "hosharaff"
         */
        company: "hosharaff" | "acanne" | "vasagrande";
      },
      params: RequestParams = {},
    ) =>
      this.request<void, string>({
        path: `/CompanyRegister/ResetForDemo`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
  };
  vero = {
    /**
     * No description
     *
     * @tags Vero
     * @name VeroControllerGetConnection
     * @request GET:/Vero/GetConnection
     */
    veroControllerGetConnection: (
      query: {
        /** Company ID */
        companyID: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ConnRecord, string>({
        path: `/Vero/GetConnection`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vero
     * @name VeroControllerGetIssuedCredentials
     * @request GET:/Vero/GetIssuedCredentials
     */
    veroControllerGetIssuedCredentials: (
      query: {
        /** Connection ID */
        connId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<V10CredentialExchangeListResult, string>({
        path: `/Vero/GetIssuedCredentials`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vero
     * @name VeroControllerGetAvailableCredentials
     * @request GET:/Vero/GetAvailableCredentials
     */
    veroControllerGetAvailableCredentials: (params: RequestParams = {}) =>
      this.request<AvailableCredential[], string>({
        path: `/Vero/GetAvailableCredentials`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vero
     * @name VeroControllerIssueCredential
     * @request POST:/Vero/IssueCredential
     */
    veroControllerIssueCredential: (
      query: {
        /** Connection ID */
        connId: string;
        /** Credential definition ID */
        credDefId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<V10CredentialExchange, string>({
        path: `/Vero/IssueCredential`,
        method: "POST",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Vero
     * @name VeroControllerSendEudtrcWithWebDid
     * @request POST:/Vero/SendEudtrcWithWebDid
     */
    veroControllerSendEudtrcWithWebDid: (
      query: {
        /** Web DID */
        webDid: string;
      },
      body: string,
      params: RequestParams = {},
    ) =>
      this.request<EudtrcIssuanceResponse, string>({
        path: `/Vero/SendEudtrcWithWebDid`,
        method: "POST",
        query: query,
        body: body,
        type: ContentType.Text,
        format: "json",
        ...params,
      }),
  };
}
